import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "@app/types/enums";

interface PlaceholderImageProps {
  firstname: string;
  lastname: string;
}

export const PlaceholderImage = ({
  firstname,
  lastname,
}: PlaceholderImageProps) => {
  return (
    <Box
      sx={{
        height: "40px",
        width: "40px",
        borderRadius: 10,
        bgcolor: Colors.GREY_200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant={"body1"} fontWeight={700}>
        {firstname[0].toUpperCase() + lastname[0].toUpperCase()}
      </Typography>
    </Box>
  );
};
