import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Colors } from "@app/types/enums";
import {
  ResetCoursesStore,
  ResetParticipantsStore,
} from "@app/modules/courses/store/actions";
import { useAuth } from "@app/auth/hooks";
import { useDispatch } from "react-redux";

export const NoAccess = () => {
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(ResetCoursesStore());
    dispatch(ResetParticipantsStore());
    logout();
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant={"h1"} color={Colors.SECONDARY_DARK}>
        Nu aveți acces pe această pagină
      </Typography>
      <Button
        onClick={onLogout}
        sx={{
          px: 4,
          py: 1,
          bgcolor: Colors.SECONDARY,
          ":hover": {
            bgcolor: Colors.SECONDARY_DARK,
          },
        }}
      >
        <Typography
          variant={"button"}
          sx={{
            textTransform: "none",
            color: Colors.WHITE,
            pr: 2,
          }}
          fontWeight={700}
        >
          Întoarce-te la pagina de autentificare
        </Typography>
      </Button>
    </Box>
  );
};
