import {
  bulkDeleteParticipantTests,
  computeTestScore,
  setParticipantAnswersForTest,
  setParticipantSection,
  setParticipantTest,
  updateParticipantTest as updatePTest,
  updateStatusOfParticipantTest,
} from "@app/modules/courses/services";
import { Status } from "@app/types/enums";
import { useDispatch, useSelector } from "react-redux";
import {
  SetCurrentParticipant,
  UpdateParticipantSection,
  UpdateParticipantTest,
} from "@app/modules/courses/store/actions";
import { ParticipantTest, Section, Test } from "@app/types/interfaces";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";
import { ParticipantAnswer } from "@app/types/interfaces/participant-answer.interface";
import { selectCurrentParticipant } from "@app/modules/courses/store/selectors";

export const useParticipantTests = () => {
  const dispatch = useDispatch();

  const participant = useSelector(selectCurrentParticipant);

  const setParticipantAnswers = async (
    testId: number,
    pTest: ParticipantTest,
    answers: { [questionId: number]: string }
  ) => {
    try {
      const participantAnswers: ParticipantAnswer[] = [];
      Object.keys(answers).forEach((questionId) => {
        const pAnswer: ParticipantAnswer = {
          answer: answers[questionId],
          participantTest: pTest.id,
          question: +questionId,
        };
        participantAnswers.push(pAnswer);
      });

      const _answers = await setParticipantAnswersForTest(participantAnswers);

      const _pTest = {
        ...pTest,
        status: Status.FINISHED,
        participant_answers: _answers,
      };

      let participantTest = await updatePTest(_pTest);

      participantTest.participantAnswers = _answers;
      dispatch(UpdateParticipantTest(participantTest));
      computeTestScore(_pTest);
    } catch (error) {
      throw error;
    }
  };

  const createParticipantTest = async (test: Test) => {
    try {
      //--------- CREATE TEST --------
      const pTest: ParticipantTest = {
        status: Status.IN_PROGRESS,
        participant: participant.id,
        test: test.id,
        participantAnswers: [],
      };

      const createdTest = await setParticipantTest(pTest);

      if (participant.teste) {
        participant.teste.push(createdTest);
      } else {
        participant.teste = [createdTest];
      }

      dispatch(UpdateParticipantTest(createdTest));
    } catch (error) {
      throw error;
    }
  };

  const updateParticipantTest = async (
    test: ParticipantTest,
    nextSection?: Section
  ) => {
    try {
      if (nextSection) {
        const pSection: ParticipantSection = {
          participant: participant.id,
          section: nextSection.id,
          status: Status.IN_PROGRESS,
        };

        const createdPSection = await setParticipantSection(pSection);
        participant.sections.push(createdPSection);

        dispatch(UpdateParticipantSection(createdPSection));
      }

      await updateStatusOfParticipantTest(test.id, Status.FINISHED);

      test.status = Status.FINISHED;
      dispatch(UpdateParticipantTest(test));
    } catch (error) {
      throw error;
    }
  };

  const deleteParticipantTests = async (tests: ParticipantTest[]) => {
    try {
      await bulkDeleteParticipantTests({
        id__in: tests
          .map((participantTest: ParticipantTest) => participantTest.id)
          .join(","),
      });
      const _participant = {
        ...participant,
        teste: participant.teste.filter(
          (participantTest: ParticipantTest) =>
            !tests.find(
              (test: ParticipantTest) => test.id === participantTest.id
            )
        ),
      };
      dispatch(SetCurrentParticipant(_participant));
    } catch (error) {
      throw error;
    }
  };

  return {
    createParticipantTest,
    setParticipantAnswers,
    updateParticipantTest,
    deleteParticipantTests,
  };
};
