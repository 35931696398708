import { Stage } from "@app/types/interfaces/stage.interface";
import { createAction } from "typesafe-actions";

const SET_STAGE_LIST = "[Monitoring] Set Stages List";

export const SetStageList = createAction(
  SET_STAGE_LIST,
  (stages: Stage[]) => stages
)<Stage[]>();
SetStageList;
