import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "@app/types/enums";
import { formatDuration } from "@app/common/utilities";

interface CourseDurationDetails {
  nbModules: number;
  totalDuration: number;
  displayRemainingNbModules?: JSX.Element;
  displayRemainingDuration?: JSX.Element;
}

export const CourseDurationDetails = ({
  nbModules,
  totalDuration,
  displayRemainingNbModules,
  displayRemainingDuration,
}: CourseDurationDetails) => {
  return (
    <Box
      sx={{
        height: "64px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "start",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          className={"prevent-text-highlight"}
          src={"/icons/book.svg"}
          alt={"book-icon"}
          style={{
            filter:
              "invert(62%) sepia(63%) saturate(313%) hue-rotate(152deg) brightness(87%) contrast(98%)",
          }}
        />
        <Typography
          variant={"body2"}
          color={Colors.PRIMARY}
          sx={{ pl: 2, pr: 1 }}
        >
          {`${nbModules} Modul${nbModules > 1 ? "e" : " "}`}
          {displayRemainingNbModules}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: 5,
        }}
      >
        <img
          className={"prevent-text-highlight"}
          src={"/icons/clock-icon.svg"}
          alt={"clock-icon"}
          style={{
            filter:
              "invert(62%) sepia(63%) saturate(313%) hue-rotate(152deg) brightness(87%) contrast(98%)",
          }}
        />
        <Typography
          variant={"body2"}
          color={Colors.PRIMARY}
          sx={{ pl: 2, pr: 1 }}
        >
          {`Durata de  ${formatDuration(totalDuration)}`}
          {displayRemainingDuration}
        </Typography>
      </Box>
    </Box>
  );
};
