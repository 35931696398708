import api from "@app/api/api.service";
import { PaginatedData, Support } from "@app/types/interfaces";

const activeSupportPath = "/records/support";

export const getActiveSupportList = (): Promise<PaginatedData<Support>> => {
  const queryParams = {
    datafy_fields: "email,telephone,active",
    active__eq: true,
    pageSize: 1,
  };
  return api.get(activeSupportPath, { params: queryParams });
};
