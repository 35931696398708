import { AppState } from "@app/store/reducers";
import { createSelector } from "reselect";
import { StageReducerState } from "@app/modules/monitoring/store/reducers/stages.reducer";

const stageState = (state: AppState): StageReducerState =>
  state.monitoringReducers.stages;

export const selectStageList = createSelector(
  stageState,
  (state) => state.stages
);

export const selectStagesLoaded = createSelector(
  stageState,
  (state) => state.stagesLoaded
);
