import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { LoginPageDisplay } from "@app/modules/auth/components";
import { useAuth } from "@app/modules/auth/hooks";
import { LoginFormData } from "@app/types/interfaces";
import { removeTokens } from "@app/api";

const LoginPage = () => {
  const [responseMessage, setResponseMessage] = useState("");
  const { login, loading } = useAuth();

  const state: any = useLocation().state;

  const onAuthenticate = async ({ email, password }: LoginFormData) => {
    try {
      removeTokens();
      await login(email, password, state?.returnUrl);
    } catch (error) {
      setResponseMessage("Email-ul sau parola sunt greșite");
    }
  };

  const onForgottenPassword = (): void => {
    //TODO
  };

  return (
    <LoginPageDisplay
      onAuthenticate={onAuthenticate}
      onForgottenPassword={onForgottenPassword}
      message={responseMessage}
      loading={loading}
    />
  );
};

export default LoginPage;
