import { BusinessPlan, CustomFile, PaginatedData } from "@app/types/interfaces";
import api from "@app/api/api.service";

const businessPlanPath = "/records/business_plan";

const businessPlanParams = {
  datafy_fields:
    "id,user,files.id,files.file,files.name,files.size,files.created_at",
};

export const setBusinessPlan = (businessPlan: BusinessPlan) => {
  return api.post<void, BusinessPlan>(businessPlanPath, businessPlan);
};

export const getBusinessPlanFilesService = (
  businessPlanId: number
): Promise<BusinessPlan> => {
  return api.get(`${businessPlanPath}/${businessPlanId}`, {
    params: businessPlanParams,
  });
};
