import React, { useEffect, useState } from "react";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { Colors, Status } from "@app/types/enums";
import { formatDuration, getCorrectColor } from "@app/common/utilities";
import { useSelector } from "react-redux";
import {
  selectCurrentSections,
  selectModuleDurationOfCurrentCourse,
  selectModuleStatus,
  selectParticipantSectionsOfModule,
  selectParticipantTestOfModule,
} from "@app/modules/courses/store/selectors";
import { Module, Section, Test } from "@app/types/interfaces";

import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";

const sectionStyle = (
  title: string,
  module: Module,
  status: Status,
  contentId: number,
  callback: (contentId: number) => void,
  isTest = false
) => (
  <Box
    onClick={() => status !== Status.NOT_STARTED && callback(contentId)}
    sx={{
      py: 0.5,
      display: "flex",
      flexDirection: "row",
      alignItems: !isTest ? "start" : "center",
      justifyContent: "start",
      width: "100%",
      height: "inherit",
      cursor: status !== Status.NOT_STARTED && "pointer",
    }}
  >
    {!isTest ? (
      <Box
        sx={{
          display: "inline-block",
          minWidth: "10px",
          minHeight: "10px",
          borderRadius: 2,
          border: "1px solid",
          borderColor: getCorrectColor(status),
          bgcolor:
            status === Status.IN_PROGRESS
              ? Colors.SECONDARY
              : status === Status.NOT_STARTED
              ? Colors.WHITE
              : Colors.SUCCESS,
          mt: 0.5,
          transition: "0.2s ease-in-out",
        }}
      />
    ) : (
      <img
        src={"/icons/test-icon.svg"}
        style={{
          maxWidth: "10px",
          maxHeight: "10px",
          filter:
            status === Status.IN_PROGRESS
              ? "invert(61%) sepia(73%) saturate(301%) hue-rotate(152deg) brightness(88%) contrast(87%)"
              : status === Status.NOT_STARTED
              ? "invert(90%) sepia(83%) saturate(6378%) hue-rotate(176deg) brightness(92%) contrast(65%)"
              : "invert(51%) sepia(79%) saturate(456%) hue-rotate(73deg) brightness(100%) contrast(85%)",
        }}
      />
    )}
    <Typography
      color={getCorrectColor(status)}
      sx={{ pl: 1, transition: "0.1s ease-in-out" }}
      variant={"caption"}
      fontWeight={status === Status.IN_PROGRESS && "600"}
    >
      {title}
    </Typography>
  </Box>
);

const sectionsList = (
  participantSections: ParticipantSection[],
  module: Module,
  viewSection: (sectionId: number) => void
) =>
  module.sections.map((_section, index) => {
    const pSectionStatus: Status = participantSections.find(
      (_pSection) => _pSection.section === _section.id
    )?.status;

    return (
      <React.Fragment key={index}>
        {sectionStyle(
          _section.title,
          module,
          pSectionStatus ? pSectionStatus : Status.NOT_STARTED,
          _section.id,
          viewSection
        )}
      </React.Fragment>
    );
  });

interface ModuleRowProps {
  module: Module;
  setCurrentSection: (currentSection: Section) => void;
  finalized: boolean;
  setTest: (test: Test) => void;
  testInProgress: boolean;
  setAnswers: (answers: { [questionId: number]: string }) => void;
  submitted: boolean;
}

export const ModuleRow = ({
  module,
  setCurrentSection,
  finalized,
  setTest,
  testInProgress,
  setAnswers,
  submitted,
}: ModuleRowProps) => {
  const [open, setOpen] = useState(false);
  const currentSectionsOfModule = useSelector(selectCurrentSections)[module.id];
  const currentModuleTest = useSelector(
    selectParticipantTestOfModule(module.test.id)
  );

  const moduleStatus = useSelector(
    selectModuleStatus(module, currentSectionsOfModule, currentModuleTest)
  );
  const participantSections = useSelector(
    selectParticipantSectionsOfModule(currentSectionsOfModule)
  );

  useEffect(() => {
    if (moduleStatus === Status.IN_PROGRESS || finalized) {
      setOpen(true);
    } else if (moduleStatus === Status.FINISHED) {
      setOpen(false);
    }
  }, [moduleStatus]);

  const viewSection = (sectionId: number) => {
    if (testInProgress) {
      if (submitted) {
        window.alert(
          "Din aceasta pagina nu se poate naviga! Va rugam sa incepeti urmatoarea parte."
        );
      } else if (
        confirm("Sigur vreţi să părăsiţi pagina? Răspunsurile vor fi pierdute!")
      ) {
        setAnswers({});
        setTest(null);
        const currentSection = currentSectionsOfModule[sectionId.toString()];
        setCurrentSection(currentSection);
      }
    } else {
      setAnswers({});
      setTest(null);
      const currentSection = currentSectionsOfModule[sectionId.toString()];
      setCurrentSection(currentSection);
    }
  };

  const viewTest = () => {
    if (testInProgress) {
      if (submitted) {
        window.alert(
          "Din aceasta pagina nu se poate naviga! Va rugam sa incepeti urmatoarea parte."
        );
      } else if (
        confirm("Sigur vreţi să părăsiţi pagina? Răspunsurile vor fi pierdute!")
      ) {
        setAnswers({});
        setTest(module.test);
      }
    } else {
      setAnswers({});
      setTest(module.test);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between",
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "start",
          width: "90%",
        }}
      >
        <img
          src={`${
            moduleStatus === Status.IN_PROGRESS
              ? "/icons/right-arrow.svg"
              : moduleStatus === Status.NOT_STARTED
              ? "/icons/not-started-icon.svg"
              : "/icons/finished-module.svg"
          }`}
          style={{ maxWidth: "20px", height: "auto", paddingTop: 1 }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            pl: 1,
          }}
        >
          <Typography color={Colors.PRIMARY} sx={{ pb: 0.5, width: "80%" }}>
            <span className={"limit-rows-to-3 sidenav-text word-wrap"}>
              {module.title}
            </span>
          </Typography>
          <Typography variant={"caption"} color={Colors.TEXT_SECONDARY}>
            Număr de capitole: {module.sections.length}
          </Typography>
          <Typography variant={"caption"} color={Colors.TEXT_SECONDARY}>
            Durata de pregătire:
            {formatDuration(
              useSelector(selectModuleDurationOfCurrentCourse(module.id))
            )}
          </Typography>

          <Collapse in={open} collapsedSize={0} sx={{ mt: 1 }}>
            {sectionsList(participantSections, module, viewSection)}
            {sectionStyle(
              module.test.title,
              module,
              currentModuleTest ? currentModuleTest.status : Status.NOT_STARTED,
              currentModuleTest ? currentModuleTest.test : null,
              viewTest,
              true
            )}
          </Collapse>
        </Box>
      </Box>

      <IconButton onClick={() => setOpen(!open)} sx={{ p: 0 }}>
        <img
          src={`${open ? "/icons/up-arrow.svg" : "/icons/down-arrow.svg"}`}
          alt={"arrow button"}
          style={{
            filter:
              "invert(50%) sepia(37%) saturate(227%) hue-rotate(165deg) brightness(94%) contrast(84%)",
          }}
        />
      </IconButton>
    </Box>
  );
};
