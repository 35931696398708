import { combineReducers } from "redux";
import coursesReducer, { CoursesReducerState } from "./courses.reducer";
import participantsReducer, {
  ParticipantsReducerState,
} from "@app/modules/courses/store/reducers/participants.reducer";

export interface CoursesState {
  courses: CoursesReducerState;
  participants: ParticipantsReducerState;
}

export const coursesReducers = combineReducers({
  courses: coursesReducer,
  participants: participantsReducer,
});
