import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import { Colors } from "@app/types/enums";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { TextInput } from "@app/common/components";
import { EMAIL } from "@app/constants";
import { LoginFormData } from "@app/types/interfaces";

interface LoginPageProps {
  onAuthenticate: (data: LoginFormData) => void;
  onForgottenPassword: () => void;
  message: string;
  loading: boolean;
}

export const LoginPageDisplay = ({
  onAuthenticate,
  onForgottenPassword,
  message,
  loading,
}: LoginPageProps) => {
  const [visible, setVisible] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: "onTouched",
  });

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: Colors.LIGHT_GREY,
      }}
      style={{ backgroundImage: `url(/images/background-login.svg)` }}
      className={"prevent-text-highlight"}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "80vw",
        }}
      >
        {/*---------------------DESCRIPTION - LEFT-------------------*/}
        <Box
          sx={{
            width: "32%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          <img
            src={"/logos/pragma-logo.svg"}
            style={{ paddingTop: "8%", width: "80%" }}
            alt={"logo"}
          />
          <Typography
            variant="h1"
            sx={{ mt: 9, mb: 2 }}
            color={Colors.INFO_MAIN}
          >
            Antreprenoriat social în mediul rural
          </Typography>
          <Typography
            variant="subtitle2"
            color={Colors.DARK_GREY}
            sx={{ mb: 16 }}
          >
            Promovarea antreprenoriatului social și a integrării vocaționale în
            întreprinderile sociale și economia socială și solidară pentru a
            facilita accesul la ocuparea forței de muncă
          </Typography>
          <img src={"/images/antet.svg"} alt={"logo"} />
          <Typography
            sx={{ mt: 2 }}
            variant="subtitle2"
            color={Colors.DARK_GREY}
          >
            Conținutul acestui material nu reprezintă în mod obligatoriu poziția
            oficială a Uniunii Europene sau a Guvernului României Pentru
            informaţii detaliate despre celelalte programe cofinanţate de
            Uniunea Europeană, va invităm să vizitaţi{" "}
            <a
              href="http://mfe.gov.ro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              mfe.gov.ro
            </a>
          </Typography>
        </Box>

        {/*-------------------FORM - RIGHT-----------------------*/}
        <Box
          sx={{
            borderRadius: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: 616,
            width: 486,
            bgcolor: "white",
            px: 13,
            py: 10,
            boxShadow: "5px 5px 10px gray",
            color: Colors.WHITE,
          }}
        >
          <Box>
            <Typography variant="h2" color={Colors.INFO_MAIN}>
              Autentifică-te în contul tău
            </Typography>
            <Typography
              variant="subtitle2"
              color={Colors.DARK_GREY}
              sx={{ mt: 2, mb: 2.5 }}
            >
              Asigură-te că toate datele sunt introduse corect.
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onAuthenticate)}>
            <Box>
              <TextInput
                disabled={loading}
                control={control}
                name={"email"}
                label={"Email"}
                type="text"
                rules={{
                  required: "Email-ul este obligatoriu",
                  pattern: {
                    value: EMAIL,
                    message: "Email-ul este gresit",
                  },
                }}
                style={{ marginBottom: "8%", width: "100%" }}
              />
              <Box>
                <Controller
                  name={"password"}
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled={loading}
                      label="Parolă"
                      type={visible ? "text" : "password"}
                      variant="filled"
                      error={!!error}
                      onBlur={onBlur}
                      value={value}
                      onChange={onChange}
                      helperText={error ? error.message : null}
                      autoComplete="off"
                      sx={{ width: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setVisible(!visible)}
                            onMouseDown={(e) => e.preventDefault()}
                            sx={{ display: loading ? "none" : "flex" }}
                          >
                            <IconButton>
                              {visible ? (
                                <img
                                  src={"/icons/not-visible.svg"}
                                  alt={"not-visible"}
                                />
                              ) : (
                                <img
                                  src={"/icons/visible.svg"}
                                  alt={"visible"}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  rules={{
                    required: "Parola e obligatorie",
                  }}
                />
              </Box>

              {message.length !== 0 && (
                <Typography
                  variant="subtitle2"
                  color={Colors.ERROR}
                  sx={{ display: "flex", justifyContent: "center", py: 2 }}
                >
                  {message}
                </Typography>
              )}

              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: `${!message.length && "16%"}`,
                    px: 3,
                    py: 2,
                    width: 1,
                    height: 42,
                    ":hover": {
                      bgcolor: Colors.PRIMARY_DARK,
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{ color: Colors.WHITE, mb: -0.5 }}
                      size={"20px"}
                    />
                  ) : (
                    "Autentificare"
                  )}
                </Button>

                <Box
                  sx={{
                    pt: 1,
                    width: 1,
                    display: "none",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    size="small"
                    color="secondary"
                    sx={{
                      pr: 0,
                      width: "40%",
                      textTransform: "none",
                    }}
                    onClick={onForgottenPassword}
                  >
                    Ai uitat parola?
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
