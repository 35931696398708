import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import PublicRoute from "./public-route";
import PrivateRoute from "./private-route";
import { Navigate } from "react-router-dom";
import { Routes as RouteEnums } from "@app/types/enums";
import SnackbarDisplay from "@app/modules/core/components/snackbar-display";

const Router = () => {
  return (
    <BrowserRouter>
      <SnackbarDisplay />
      <Routes>
        {publicRoutes.map(({ key, path, component }) => (
          <Route
            key={key}
            path={path}
            element={<PublicRoute component={component} />}
          />
        ))}
        {privateRoutes.map(({ key, path, component }) => (
          <Route
            key={key}
            path={path}
            element={<PrivateRoute component={component} />}
          />
        ))}
        <Route
          path={"/"}
          element={
            <PrivateRoute
              component={() => <Navigate to={RouteEnums.LOGIN} />}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
