import React, { useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Colors, Routes, Status } from "@app/types/enums";
import { Course, Module, Participant } from "@app/types/interfaces";
import { useSelector } from "react-redux";
import {
  selectCourseDuration,
  selectNbOfDoneModules,
} from "@app/modules/courses/store/selectors";
import { useNavigate } from "react-router-dom";
import { CourseDurationDetails } from "@app/common/components";
import { formatDuration } from "@app/common/utilities";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";

interface CourseCard {
  course: Course;
  participant: Participant;
}

export const CourseCard = ({ course, participant }: CourseCard) => {
  const nbModules = course.modules.length;
  const totalDuration = useSelector(selectCourseDuration(course.id));
  const doneModules = useSelector(selectNbOfDoneModules(course));
  const remainingNbModules = nbModules - doneModules;
  const durationDone = participant ? participant.duration : 0;
  const progress = participant ? participant.progress : 0;
  const remainingDuration = totalDuration - durationDone;
  const started = !!participant;
  const finalized =
    progress === 100 &&
    participant?.teste.filter((test) => test.status !== Status.FINISHED)
      .length === 0 &&
    participant?.teste?.length ===
      course.modules.filter((module: Module) => !!module.test).length +
        (course.test ? 1 : 0);

  const navigate = useNavigate();

  const displayRemainingNbModules = started && !finalized && (
    <span>
      |
      <span style={{ color: Colors.SUCCESS, paddingLeft: 2 }}>
        {remainingNbModules} rămas{remainingNbModules > 1 ? "e" : " "}
      </span>
    </span>
  );
  const displayRemainingDuration = started && !finalized && (
    <span>
      |
      <span style={{ color: Colors.SUCCESS, paddingLeft: 2 }}>
        {formatDuration(remainingDuration)} rămase
      </span>
    </span>
  );

  return (
    <Box
      sx={{
        width: "100%",
        minWidth: 360,
        height: 440,
        bgcolor: Colors.WHITE,
        display: "flex",
        flexDirection: "column",
        pb: 3,
        px: 4,
      }}
    >
      <Box
        className={"prevent-text-highlight"}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          backgroundImage: `linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)), url(${
            course.image ? course.image.file : "/images/card-image.png"
          })`,
          backgroundSize: "cover",
          height: 300,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        }}
      >
        <Typography
          variant={"subtitle2"}
          style={{
            position: "absolute",
            bottom: 38,
            left: 24,
            zIndex: 1,
            color: Colors.WHITE,
          }}
        >
          Progres:
        </Typography>

        <Box
          sx={{
            height: "10%",
            width: "60%",
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 19,
            left: 24,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              width: "80%",
              height: 4,
              borderRadius: 5,
            }}
          />
          <Typography variant={"caption"} color={Colors.WHITE} sx={{ ml: 2 }}>
            {progress}%
          </Typography>
        </Box>

        <Box
          sx={{
            bgcolor: finalized
              ? Colors.SUCCESS
              : started
              ? Colors.SECONDARY
              : Colors.LIGHT_GREY,
            color: started || finalized ? Colors.WHITE : Colors.PRIMARY,
            width: "fit-content",
            maxWidth: 80,
            px: 1,
            py: 0.5,
            borderRadius: 2,
            position: "absolute",
            zIndex: 1,
            bottom: 16,
            right: 24,
          }}
        >
          <span className={"tag limit-rows-to-1"}>
            {finalized ? "Finalizat" : started ? "Început" : "Neînceput"}
          </span>
        </Box>
      </Box>

      <Box
        sx={{
          border: 2,
          borderTop: 0,
          borderColor: Colors.GREY_300,
          borderRadius: 3,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: "100%",
          display: "flex",
          p: 3,
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography variant={"subtitle2"} color={Colors.PRIMARY}>
            <span
              className={"limit-rows-to-2"}
              style={{ margin: 0, fontWeight: 700, fontSize: 16 }}
            >
              {course.title}
            </span>
          </Typography>
          <Divider
            color={Colors.GREY_300}
            sx={{ width: 113, height: 2, my: 2 }}
          />
        </Box>
        <CourseDurationDetails
          nbModules={nbModules}
          totalDuration={totalDuration}
          displayRemainingDuration={displayRemainingDuration}
          displayRemainingNbModules={displayRemainingNbModules}
        />

        <Button
          onClick={() => navigate(Routes.COURSES + "/" + course.id)}
          variant="contained"
          sx={{
            bgcolor: finalized
              ? Colors.SUCCESS
              : started
              ? Colors.SECONDARY
              : Colors.PRIMARY,
            cursor: "pointer",
            boxShadow: 0,
            textTransform: "none",
            "&:hover": {
              backgroundColor: finalized
                ? Colors.SUCCESS_DARK
                : started
                ? Colors.SECONDARY_DARK
                : Colors.PRIMARY_DARK,
              boxShadow: 0,
            },
          }}
        >
          {finalized
            ? "Vizualizează cursul"
            : started
            ? "Continuă cursul"
            : "Află mai multe"}
        </Button>
      </Box>
    </Box>
  );
};
