import { combineReducers } from "redux";
import authReducer, { AuthReducerState } from "./auth.reducer";

export interface AuthState {
  auth: AuthReducerState;
}

export const authReducers = combineReducers({
  auth: authReducer,
});
