import { PRAGMA_API } from "@app/constants";
import axios from "axios";

// TODO Add authentication header after apis will be authenticated
const pragmaApi = axios.create({
  baseURL: PRAGMA_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export default pragmaApi;
