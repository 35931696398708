import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  login as loginService,
  logout as logoutService,
} from "@app/modules/auth/services";
import { Routes } from "@app/types/enums";
import { useDispatch } from "react-redux";
import { SetUser } from "@app/modules/auth/actions";

export const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (email: string, password: string, returnUrl?: string) => {
    try {
      setLoading(true);
      const user = await loginService(email, password);
      setLoading(false);
      dispatch(SetUser(user));
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const logout = (): void => {
    logoutService();
    dispatch(SetUser(null));
    navigate(Routes.LOGIN);
  };

  return {
    login,
    logout,
    loading,
  };
};
