import { getStagesService } from "@app/modules/monitoring/services";
import { useEffect, useState } from "react";
import { Stage } from "@app/types/interfaces/stage.interface";
import { orderStagesByDeadline } from "@app/common/utilities";
import { useDispatch, useSelector } from "react-redux";
import { SetStageList } from "@app/modules/monitoring/store/actions";
import {
  selectStageList,
  selectStagesLoaded,
} from "@app/modules/monitoring/store/selectors";

export const useLoadStages = () => {
  const dispatch = useDispatch();

  const [loadingStages, setLoadingStages] = useState(true);

  const stagesLoaded = useSelector(selectStagesLoaded);

  useEffect(() => {
    if (stagesLoaded) {
      setLoadingStages(false);
      return;
    }
    getStages();
  }, []);

  const getStages = async () => {
    console.warn("fetch stages");
    try {
      const { results } = await getStagesService();
      const stages = orderStagesByDeadline(results);
      dispatch(SetStageList(stages));
      setLoadingStages(false);
    } catch (error) {
      setLoadingStages(false);
      throw error;
    }
  };

  return {
    loadingStages,
  };
};
