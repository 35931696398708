import { combineReducers } from "redux";
import stagesReducer, {
  StageReducerState,
} from "@app/modules/monitoring/store/reducers/stages.reducer";

export interface MonitoringState {
  stages: StageReducerState;
}

export const monitoringReducers = combineReducers({
  stages: stagesReducer,
});
