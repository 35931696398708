import api from "@app/api/api.service";
import { PaginatedData, CustomFile } from "@app/types/interfaces";

const participantFilePath = "/records/file";

export const addFile = (file) => {
  return api.post<void, FormData>(`${participantFilePath}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteSelectedFiles = (fileIds: string) => {
  return api.delete<void, CustomFile[]>(
    `${participantFilePath}/bulk?id__in=${fileIds}`
  );
};

export const deleteFile = (fileId: number) => {
  return api.delete(`${participantFilePath}/${fileId}`);
};

export const getParticipantFiles = (
  participantId: number
): Promise<PaginatedData<CustomFile>> => {
  return api.get(participantFilePath + "?participant__eq=" + participantId);
};
