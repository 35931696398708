import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentCourse } from "@app/modules/courses/store/selectors";
import { Colors, Status } from "@app/types/enums";
import {
  Participant,
  ParticipantTest,
  Section,
  Test,
} from "@app/types/interfaces";
import Lottie from "lottie-react";
import animation from "../../../common/animations/success-animation.json";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";
import CircularProgress from "@mui/material/CircularProgress";

interface TestFinishedProps {
  onNextChapterButtonClick: () => void;
  setSubmitted: (submitted: boolean) => void;
  setAnswers: (answers: { [questionId: number]: string }) => void;
  test: Test;
  participant: Participant;
  loading: boolean;
  nextContent: ParticipantTest | ParticipantSection;
}

export const TestFinished = ({
  onNextChapterButtonClick,
  setSubmitted,
  setAnswers,
  test,
  participant,
  loading,
  nextContent,
}: TestFinishedProps) => {
  const course = useSelector(selectCurrentCourse);
  const finalized =
    participant.progress === 100 &&
    participant?.teste?.filter((test) => test.status !== Status.FINISHED)
      .length === 0;

  const itemList =
    test.id === course.test.id
      ? course.modules.map((module) =>
          module.sections
            .filter((_section: Section) =>
              participant.sections.find(
                (_pSection: ParticipantSection) =>
                  _pSection.section === _section.id
              )
            )
            .map((section, index) => (
              <Typography
                key={index}
                variant={"body2"}
                color={Colors.TEXT_SECONDARY}
                sx={{ py: 0.5 }}
              >
                {section.title}
              </Typography>
            ))
        )
      : course.modules
          .find((module) => module.test.id === test.id)
          .sections.filter((_section: Section) =>
            participant.sections.find(
              (_pSection: ParticipantSection) =>
                _pSection.section === _section.id
            )
          )
          .map((section, index) => (
            <Typography
              key={index}
              variant={"body2"}
              color={Colors.TEXT_SECONDARY}
              sx={{ py: 0.5 }}
            >
              {section.title}
            </Typography>
          ));

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant={"h3"} fontWeight={"700"} color={Colors.PRIMARY}>
          Felicitări!
        </Typography>
        <Typography variant={"body2"} color={Colors.PRIMARY} sx={{ py: 2 }}>
          {test.id === course.test.id ? (
            <span>
              Ați finalizat cursul{" "}
              <span style={{ fontWeight: "700" }}>{course.title}</span>. Găsiți
              mai jos rezumatul acestuia.
            </span>
          ) : (
            "Tocmai ați trecut cu succes testul acestui modul"
          )}
        </Typography>
        <Divider
          color={Colors.GREY_300}
          sx={{ width: 113, height: 2, mt: 1 }}
        />
      </Box>
      <Lottie animationData={animation} style={{ width: "300px" }} />
      <Box
        sx={{
          height: "200px",
          width: "100%",
          overflowX: "scroll",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          mb: 4,
        }}
      >
        <Typography
          variant={"body2"}
          fontWeight={700}
          color={Colors.BLACK}
          sx={{ pb: 1 }}
        >
          Capitole
        </Typography>
        {itemList}
      </Box>
      <Button
        disabled={loading}
        onClick={async () => {
          setAnswers({});
          await onNextChapterButtonClick();
          setSubmitted(false);
        }}
        sx={{
          width: "55%",
          mt: 5,
          px: 2,
          py: 1,
          bgcolor: Colors.SUCCESS,
          ":hover": {
            bgcolor: Colors.SUCCESS_DARK,
          },
        }}
      >
        {loading ? (
          <CircularProgress sx={{ color: Colors.WHITE }} size={"20px"} />
        ) : (
          <Typography
            variant={"button"}
            sx={{
              textTransform: "none",
              color: Colors.WHITE,
              pr: 2,
            }}
            fontWeight={700}
          >
            {finalized && !nextContent
              ? "Finalizează"
              : finalized && nextContent && nextContent["isTest"]
              ? "Începe testul final"
              : "Începe următorul modul"}
          </Typography>
        )}
        {!loading && (
          <img
            src={"/icons/right-arrow.svg"}
            style={{
              filter:
                "invert(100%) sepia(100%) saturate(1%) hue-rotate(211deg) brightness(110%) contrast(101%)",
            }}
          />
        )}
      </Button>
    </Box>
  );
};
