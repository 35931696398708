import { Settings } from "@app/types/interfaces";
import { useEffect, useState } from "react";
import { getActiveSettings } from "@app/modules/core/services/settings.service";

export const useSettings = () => {
  const [settings, setSettings] = useState<Settings>(null);
  const [loadingSettings, setLoadingSettings] = useState(true);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    try {
      const activeSettings = await getActiveSettings();
      setSettings(activeSettings.results[0]);
      setLoadingSettings(false);
    } catch (error) {
      setLoadingSettings(false);
      throw error;
    }
  };

  return {
    settings,
    loadingSettings,
  };
};
