import { Token, User } from "@app/types/interfaces";
import { setTokens, removeTokens } from "@app/api";
import api from "@app/api/api.service";

const logInPath = "/auth/signIn";
const userPath = "/auth/user";

const userParams = {
  datafy_fields:
    "id,email,admin,disabled,first_name,last_name,groups,learning_stage,monitoring_stage,business_plan_stage,business_plan.id,business_plan.user,business_plan.files.id,business_plan.files.file,business_plan.files.name,business_plan.files.size,business_plan.files.created_at",
};

export const login = async (email: string, password: string) => {
  const token = await api.post<void, Token>(logInPath, {
    email,
    password,
  });
  setTokens(token);
  return getUser();
};

export const getUser = (): Promise<User> => {
  return api.get(userPath, {
    params: userParams,
  });
};

export const logout = () => {
  removeTokens();
};
