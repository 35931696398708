import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  selectCurrentUser,
  selectIsAuth,
} from "@app/modules/auth/store/selectors";
import { useLocation } from "react-router-dom";
import { Routes } from "@app/types/enums";
import { Sidenav } from "@app/modules/core/containers";
import { SidenavItems, sidenavItems } from "@app/constants";

interface RouteProps {
  component: FunctionComponent;
}

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAuth = useSelector(selectIsAuth);
  const { pathname } = useLocation();
  const user = useSelector(selectCurrentUser);

  const noAccess =
    user &&
    user[
      sidenavItems.find((_item: SidenavItems) => pathname.includes(_item.path))
        ?.stage
    ] === false;

  return isAuth ? (
    noAccess ? (
      <Navigate to={Routes.NO_ACCESS} />
    ) : (
      <Sidenav>
        <Component {...rest} />
      </Sidenav>
    )
  ) : (
    <Navigate to={Routes.LOGIN} state={{ returnUrl: pathname }} />
  );
};

export default PrivateRoute;
