import { createSelector } from "reselect";
import { CoursesReducerState } from "@app/modules/courses/store/reducers/courses.reducer";
import { AppState } from "@app/store/reducers";
import { calculateDurationOfCourse } from "@app/common/utilities";
import {
  Course,
  Module,
  Participant,
  ParticipantTest,
  Section,
} from "@app/types/interfaces";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";

const coursesState = (state: AppState): CoursesReducerState =>
  state.coursesReducers.courses;
export const selectCoursesList = createSelector(
  coursesState,
  (state: CoursesReducerState) => Object.values(state.courses)
);

export const selectCurrentCourse = createSelector(
  coursesState,
  (state: CoursesReducerState) => state.currentCourse
);

export const selectCurrentSections = createSelector(
  coursesState,
  (state: CoursesReducerState) => state.currentSections
);

export const selectCoursesLoading = createSelector(
  coursesState,
  (state: CoursesReducerState) => state.coursesLoaded
);

export const selectCurrentCourseLoaded = createSelector(
  coursesState,
  (state: CoursesReducerState) => state.currentCourseLoaded
);

export const selectCourse = (courseId: number) =>
  createSelector(
    coursesState,
    (state: CoursesReducerState) => state.courses[courseId]
  );

export const selectCourseDuration = (courseId: number) =>
  createSelector(selectCourse(courseId), (course) => {
    return calculateDurationOfCourse(course);
  });

export const selectCurrentCourseDuration = () =>
  createSelector(selectCurrentCourse, (course) => {
    return calculateDurationOfCourse(course);
  });

export const selectModuleDurationOfCurrentCourse = (moduleId: number) =>
  createSelector(selectCurrentSections, (currentSections) => {
    return Object.values(currentSections[moduleId]).reduce(
      (sum, section: Section) => sum + section.duration,
      0
    );
  });

export const selectAllSections = createSelector(
  coursesState,
  (state: CoursesReducerState) =>
    Object.assign({}, ...Object.values(state.currentSections))
);

export const selectAllTests = createSelector(
  selectCurrentCourse,
  (course: Course) => {
    const tests = course.modules.reduce((result, module: Module) => {
      return {
        ...result,
        [module.test.id]: module.test,
      };
    }, {});
    tests[course.test.id] = course.test;
    return tests;
  }
);

export const selectNextContent = (participant: Participant) =>
  createSelector(selectCurrentCourse, (course: Course) => {
    let firstTest = null,
      firstSection = null;
    course.modules.every((module: Module) => {
      firstSection = module.sections.find(
        (section: Section) =>
          !participant?.sections?.find(
            (participantSection: ParticipantSection) =>
              participantSection.section === section.id
          )
      );
      if (firstSection) {
        return false;
      }
      firstTest = !participant?.teste?.find(
        (participantTest: ParticipantTest) =>
          participantTest.test === module.test.id
      )
        ? module.test
        : null;
      if (firstTest) {
        return false;
      }

      return true;
    });
    if (firstSection) {
      return firstSection;
    }
    if (!firstTest) {
      firstTest = !participant?.teste?.find(
        (participantTest: ParticipantTest) =>
          participantTest.test === course.test.id
      )
        ? course.test
        : null;
    }
    if (firstTest) {
      return { ...firstTest, isTest: true };
    }

    return null;
  });
