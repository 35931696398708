import React from "react";
import { SidenavDisplay } from "@app/modules/core/components";
import Box from "@mui/material/Box/Box";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/modules/auth/store/selectors";
import { Colors } from "@app/types/enums";

interface SidenavProps {
  children: React.ReactNode;
}

export const Sidenav = ({ children }: SidenavProps) => {
  const user = useSelector(selectCurrentUser);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box sx={{ minWidth: "maxContent" }}>
        <SidenavDisplay user={user} />
      </Box>
      <Box
        sx={{ width: "100%", overflowX: "clip", bgcolor: Colors.LIGHT_GREY }}
      >
        {children}
      </Box>
    </Box>
  );
};
