import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentCourse,
  selectCurrentCourseDuration,
  selectCurrentParticipant,
} from "@app/modules/courses/store/selectors";
import { selectCurrentUser } from "@app/auth/store/selectors";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";

import { Participant, Section } from "@app/types/interfaces";
import { Status } from "@app/types/enums";
import {
  AddParticipant,
  SetCurrentParticipant,
  UpdateParticipant,
  UpdateParticipantSection,
} from "@app/modules/courses/store/actions/participants.actions";
import {
  bulkDeleteParticipantSections,
  createParticipant,
  setParticipantSection,
  updateParticipantProgress,
  updateStatusOfParticipantService,
} from "@app/modules/courses/services";
import { useState } from "react";

export const useParticipantSections = () => {
  const user = useSelector(selectCurrentUser);
  const currentCourse = useSelector(selectCurrentCourse);
  const participant = useSelector(selectCurrentParticipant);

  const totalCourseDuration = useSelector(selectCurrentCourseDuration());

  const dispatch = useDispatch();

  const createPSection = async (section: Section) => {
    const pSection: ParticipantSection = {
      participant: participant.id,
      section: section.id,
      status: Status.IN_PROGRESS,
    };

    const createdPSection = await setParticipantSection(pSection);
    participant.sections.push(createdPSection);

    dispatch(UpdateParticipantSection(createdPSection));
  };

  const updateParticipantSectionsForStartingNextChapter = async (
    currentPSectionDuration: number,
    currentPSection: ParticipantSection,
    nextSection?: Section
  ) => {
    try {
      await updateStatusOfParticipantService(
        currentPSection.id,
        Status.FINISHED
      );

      if (nextSection) {
        await createPSection(nextSection);
      }

      //update progress and duration
      participant.duration += currentPSectionDuration;
      participant.progress = Math.floor(
        (participant.duration / totalCourseDuration) * 100
      );

      await updateParticipantProgress(
        participant.id,
        participant.duration,
        participant.progress
      );

      //update participant
      dispatch(UpdateParticipant(participant));

      //update section
      currentPSection.status = Status.FINISHED;
      dispatch(UpdateParticipantSection(currentPSection));
    } catch (error) {
      throw error;
    }
  };

  const [participantLoading, setParticipantLoading] = useState(false);
  const initializeParticipantForNewlyCreatedCourse = async () => {
    try {
      setParticipantLoading(true);
      const newParticipant: Participant = {
        course: currentCourse.id,
        user: user.id,
        sections: [],
        teste: [],
        progress: 0.0,
        duration: 0,
        files: [],
      };

      let participant: Participant = await createParticipant(newParticipant);

      const pSection: ParticipantSection = {
        participant: participant.id,
        section: currentCourse.modules[0].sections[0].id,
        status: Status.IN_PROGRESS,
      };

      const createdPSection = await setParticipantSection(pSection);
      participant.sections = [createdPSection];

      dispatch(SetCurrentParticipant(participant));
      dispatch(AddParticipant(participant));

      setParticipantLoading(false);
    } catch (error) {
      setParticipantLoading(false);
      console.error(error);
    }
  };

  const deleteParticipantSections = async (sections: ParticipantSection[]) => {
    try {
      await bulkDeleteParticipantSections({
        id__in: sections
          .map(
            (participantSection: ParticipantSection) => participantSection.id
          )
          .join(","),
      });
      const _participant = {
        ...participant,
        sections: participant.sections.filter(
          (participantSection: ParticipantSection) =>
            !sections.find(
              (section: ParticipantSection) =>
                section.id === participantSection.id
            )
        ),
      };
      dispatch(SetCurrentParticipant(_participant));
    } catch (error) {
      throw error;
    }
  };

  return {
    updateParticipantSectionsForStartingNextChapter,
    initializeParticipantForNewlyCreatedCourse,
    participantLoading,
    createPSection,
    deleteParticipantSections,
  };
};
