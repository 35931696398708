import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/auth/store/selectors";
import { useEffect, useState } from "react";
import { selectParticipantsLoading } from "@app/modules/courses/store/selectors/participants.selectors";
import { SetParticipantsList } from "@app/modules/courses/store/actions/participants.actions";
import { getParticipantsOfUserId } from "@app/modules/courses/services";

export const useLoadParticipants = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);
  const participantsLoaded = useSelector(selectParticipantsLoading);

  const [loadingParticipants, setLoadingParticipants] = useState(true);

  useEffect(() => {
    if (participantsLoaded) {
      setLoadingParticipants(false);
      return;
    }
    getParticipants(user.id);
  }, []);

  const getParticipants = async (userId: string) => {
    try {
      const { results } = await getParticipantsOfUserId(userId);
      dispatch(SetParticipantsList(results));
      setLoadingParticipants(false);
    } catch (error) {
      setLoadingParticipants(false);
    }
  };

  return {
    loadingParticipants,
  };
};
