import React from "react";
import { Colors, Routes } from "@app/types/enums";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface PageLayoutProps {
  title?: string;
  subtitle?: string;
  backButton?: boolean;
  children: any;
}

export const PageLayout = ({
  title,
  subtitle,
  backButton,
  children,
}: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        color: Colors.BLACK,
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        justifyContent: backButton ? "center" : "space-around",
        px: 4,
      }}
    >
      {!backButton && (
        <Box>
          <Typography variant={"h2"} color={Colors.BLACK} sx={{ mt: 4, pb: 2 }}>
            {title}
          </Typography>
          <Typography variant={"subtitle2"} color={Colors.BLACK}>
            {subtitle}
          </Typography>
        </Box>
      )}

      {backButton && (
        <Button
          onClick={() => navigate(Routes.COURSES)}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            width: "fit-content",
            textTransform: "none",
            my: 2,
          }}
        >
          <img src={"/icons/back-arrow.svg"} alt={"back arrow"} />
          <Typography variant={"body2"} color={Colors.PRIMARY} sx={{ pl: 1 }}>
            Mergi înapoi
          </Typography>
        </Button>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: backButton ? "90vh" : "80vh",
          width: "100%",
          bgcolor: Colors.WHITE,
          borderRadius: 4,
          overflowY: "scroll",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
