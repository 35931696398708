import Box from "@mui/material/Box/Box";
import React, { useState } from "react";
import { Colors, Routes } from "@app/types/enums";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import { sidenavItems } from "@app/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "@app/types/interfaces";
import { useAuth } from "@app/modules/auth/hooks";
import { ResetCoursesStore } from "@app/modules/courses/store/actions";
import { useDispatch } from "react-redux";
import { ResetParticipantsStore } from "@app/modules/courses/store/actions/participants.actions";
import { PlaceholderImage } from "@app/modules/core/components/placeholder-image";

interface SidenavDisplayProps {
  user: User;
}

export const SidenavDisplay = ({ user }: SidenavDisplayProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [closed, setClosed] = useState<boolean>(window.innerWidth <= 1024);
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(ResetCoursesStore());
    dispatch(ResetParticipantsStore());
    logout();
  };

  return (
    <Box
      sx={{
        bgcolor: Colors.PRIMARY,
        color: Colors.WHITE,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        width: !closed ? (window.innerWidth <= 1024 ? 240 : 320) : 80,
        transition: "0.5s ease-in-out",
        overflowX: "hidden",
      }}
    >
      <Box>
        <Box sx={{ pt: 4, pb: !closed ? 3 : 4, pl: 4 }}>
          <img
            src={`${
              closed
                ? "/logos/pragma-partial-logo.svg"
                : "/logos/pragma-logo-white.svg"
            }`}
            alt={"pragma logo"}
          />
        </Box>
        <Divider color={Colors.TEXT_SECONDARY} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: !closed ? 3 : 2,
            pt: 5,
          }}
        >
          {sidenavItems.map(
            (item, index) =>
              user[item.stage] && (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: !closed ? "start" : "center",
                    alignItems: "center",
                    bgcolor: location.pathname.includes(item.path)
                      ? Colors.SECONDARY_DARK
                      : "transparent",
                    py: 1,
                    px: 1,
                    my: 0.5,
                    borderRadius: 1.5,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(item.path)}
                  className={"prevent-text-highlight"}
                >
                  <img
                    src={item.iconSrc}
                    alt={item.title}
                    style={{
                      filter: location.pathname.includes(item.path)
                        ? "invert(100%) sepia(100%) saturate(0%) hue-rotate(19deg) brightness(104%) contrast(101%)"
                        : "invert(55%) sepia(5%) saturate(1471%) hue-rotate(166deg) brightness(90%) contrast(89%)",
                    }}
                  />
                  <Typography
                    color={
                      location.pathname.includes(item.path)
                        ? Colors.WHITE
                        : Colors.TEXT_SECONDARY
                    }
                    sx={{
                      pl: 1,
                      display: closed ? "none" : "flex",
                      overflow: "hidden",
                    }}
                  >
                    <span className={"limit-rows-to-1"} style={{ margin: 0 }}>
                      {item.title}
                    </span>
                  </Typography>
                </Box>
              )
          )}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: !closed ? "start" : "center",
            alignItems: "center",
            py: 2,
            my: 2.5,
            pl: !closed ? 4 : 0,
            cursor: "pointer",
          }}
          onClick={() => {
            if (
              window.history.state.showConfirmation &&
              !window.confirm(
                "Sigur vreţi să părăsiţi pagina? Aveţi modificări nesalvate!"
              )
            ) {
              return;
            }
            onLogout();
          }}
        >
          <img
            src={"/icons/exit-icon.svg"}
            alt={"exit"}
            style={{
              filter:
                "invert(96%) sepia(0%) saturate(7485%) hue-rotate(302deg) brightness(105%) contrast(100%)",
            }}
          />
          <Typography
            color={Colors.WHITE}
            sx={{
              px: 2,
              display: closed ? "none" : "flex",
              overflow: "hidden",
            }}
          >
            <span className={"limit-rows-to-1"} style={{ margin: 0 }}>
              Ieșire din cont
            </span>
          </Typography>
        </Box>
        <Divider color={Colors.TEXT_SECONDARY} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            py: 2,
            px: 1,
          }}
        >
          <Button
            sx={{
              display: closed ? "none" : "flex",
              pl: 2,
              pr: 5,
              bgcolor:
                location.pathname === Routes.PROFILE
                  ? Colors.SECONDARY_DARK
                  : "transparent",
              cursor: "pointer",
              boxShadow: 0,
              textTransform: "none",
              "&:hover": {
                backgroundColor:
                  location.pathname === Routes.PROFILE
                    ? Colors.SECONDARY_DARK
                    : "transparent",
                boxShadow: 0,
              },
            }}
            onClick={() => navigate(Routes.PROFILE)}
            startIcon={
              <PlaceholderImage
                firstname={user.firstName}
                lastname={user.lastName}
              />
            }
            variant="contained"
          >
            <span
              className={"limit-rows-to-1 sidenav-text"}
              style={{
                margin: 0,
                color: Colors.WHITE,
                overflow: "hidden",
                paddingLeft: "16px",
              }}
            >
              {user.firstName + " " + user.lastName}
            </span>
          </Button>

          <IconButton onClick={() => setClosed(!closed)}>
            <img
              src={`${
                !closed ? "/icons/back-icon.svg" : "/icons/next-icon.svg"
              }`}
              alt={"back button"}
              style={{
                filter:
                  "invert(100%) sepia(28%) saturate(2%) hue-rotate(207deg) brightness(114%) contrast(100%)",
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
