import React from "react";
import Router from "@app/routes";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { useLoadUser } from "@app/auth/hooks";
import theme from "@app/common/theme";
import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "@app/types/enums";

function App() {
  const { userLoading } = useLoadUser();

  const renderApp = () => {
    if (userLoading) {
      return (
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: Colors.SECONDARY }} size={"80px"} />
        </Box>
      );
    }
    return (
      <>
        <Router />
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {renderApp()}
    </ThemeProvider>
  );
}

export default App;
