import { getUserCourses } from "@app/modules/courses/services";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/auth/store/selectors";
import { SetCoursesList } from "@app/modules/courses/store/actions";
import { selectCoursesLoading } from "@app/modules/courses/store/selectors";
import { Group } from "@app/modules/courses/types/interfaces";

export const useLoadCourses = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);
  const coursesLoaded = useSelector(selectCoursesLoading);

  const [loadingCourses, setLoadingCourses] = useState(true);

  useEffect(() => {
    if (coursesLoaded) {
      setLoadingCourses(false);
      return;
    }
    getCourses(user.groups);
  }, []);

  const getCourses = async (groups: number[]) => {
    try {
      if (groups.length) {
        const { results } = await getUserCourses(groups);
        const courses = results.map((group: Group) => group.course);
        dispatch(SetCoursesList(courses));
      }
      setLoadingCourses(false);
    } catch (error) {
      setLoadingCourses(false);
      throw error;
    }
  };

  return {
    loadingCourses,
  };
};
