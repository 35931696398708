import { useDispatch } from "react-redux";
import { Snackbar } from "@app/types/interfaces";
import { CloseSnackbar, OpenSnackbar } from "@app/modules/core/store/actions";
import { Severity } from "@app/types/enums";

export const useSnackbars = () => {
  const dispatch = useDispatch();

  const openSnackbar = (snackbar: Snackbar) => {
    dispatch(OpenSnackbar(snackbar));
  };

  const openError = (snackbar: Snackbar) => {
    openSnackbar({
      ...snackbar,
      severity: Severity.ERROR,
    });
  };

  const openSuccess = (snackbar: Snackbar) => {
    openSnackbar({
      ...snackbar,
      severity: Severity.SUCCESS,
    });
  };

  const closeSnackbar = () => {
    dispatch(CloseSnackbar());
  };

  return { openError, openSuccess, closeSnackbar };
};
