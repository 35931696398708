import api from "@app/api/api.service";
import { Course, PaginatedData } from "@app/types/interfaces";
import { Group } from "@app/modules/courses/types/interfaces";

const coursesPath = "/records/course";
const groupsPath = "/records/group";

const courseQueryParams = {
  datafy_fields:
    "id,title,description,test.id,test.title,test.questions.id,test.questions.question,test.questions.choices,image.file,modules.id,modules.title,modules.order,modules.course,modules.sections.id,modules.sections.title,modules.sections.content,modules.sections.duration,modules.sections.order,modules.sections.module,modules.test.id,modules.test.title,modules.test.questions.id,modules.test.questions.question,modules.test.questions.choices",
};
const groupQueryParams = {
  datafy_fields:
    "course.id,course.title,course.test,course.description,course.image.file,course.modules.id,course.modules.test,course.modules.title,course.modules.order,course.modules.course,course.modules.sections.id,course.modules.sections.title,course.modules.sections.content,course.modules.sections.duration,course.modules.sections.order,course.modules.sections.module",
};

export const getUserCourses = (
  groups: number[]
): Promise<PaginatedData<Group>> => {
  return api.get(`${groupsPath}`, {
    params: { ...groupQueryParams, id__in: groups.join(",") },
  });
};

export const getCourse = (courseId: number): Promise<Course> => {
  return api.get(`${coursesPath}/${courseId}`, {
    params: courseQueryParams,
  });
};
