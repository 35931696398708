import { Stage } from "@app/types/interfaces/stage.interface";
import { getType } from "typesafe-actions";
import { SetStageList } from "@app/modules/monitoring/store/actions";

export interface StageReducerState {
  stages: Stage[];
  stagesLoaded: boolean;
}

const initialState: StageReducerState = {
  stages: [],
  stagesLoaded: false,
};

export default (state = initialState, action): StageReducerState => {
  switch (action.type) {
    case getType(SetStageList): {
      return { ...state, stages: action.payload, stagesLoaded: true };
    }
    default:
      return state;
  }
};
