import { PageLayout } from "@app/common/components";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UploadFiles } from "@app/modules/courses/components";
import { Colors } from "@app/types/enums";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/auth/store/selectors";
import { useBusinessPlan } from "@app/modules/business-plan/hooks";
import { CustomFile, Settings } from "@app/types/interfaces";
import { formatBytes } from "@app/common/utilities";
import { useSnackbars } from "@app/modules/core/hooks";
import CircularProgress from "@mui/material/CircularProgress";
interface BusinessPlanDisplayProps {
  settings: Settings;
  loadingSettings: boolean;
}

export const BusinessPlanDisplay = ({
  settings,
  loadingSettings,
}: BusinessPlanDisplayProps) => {
  const user = useSelector(selectCurrentUser);
  const [files, setFiles] = useState<{ [id: number]: CustomFile }>({});
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { userFilesLoading, uploadUserFiles, getUserFiles, deleteUserFile } =
    useBusinessPlan();
  const { openError } = useSnackbars();

  useEffect(() => {
    if (!!user.businessPlan && user.businessPlan.files.length !== 0) {
      const _files: { [id: number]: CustomFile } = {};
      user.businessPlan.files.forEach((_file: CustomFile) => {
        _files[_file.id] = _file;
      });
      setFiles(_files);
    }
  }, [user.businessPlan]);

  const onDelete = async (fileId: number) => {
    try {
      setLoadingDelete(true);
      await deleteUserFile(fileId);

      const _list = files;
      delete _list[fileId];
      setFiles({ ..._list });

      setOpenModal(false);
      setFileToDelete(null);
      setLoadingDelete(false);
    } catch (error) {
      setLoadingDelete(false);
      openError({ message: "Ne pare rău, dar a apărut o eroare" });
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: Colors.WHITE,
              borderRadius: 3,
              py: 4,
              px: 5,
              height: 240,
              width: 400,
            }}
          >
            <Typography variant={"body2"} color={Colors.PRIMARY}>
              Ești sigur că vrei să ștergi acest fișier?
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography variant={"body2"} color={Colors.TEXT_SECONDARY}>
                <span className={"limit-rows-to-2"}>
                  {files[fileToDelete]?.name}
                </span>
              </Typography>
              <Typography variant={"caption"} color={Colors.TEXT_SECONDARY}>
                Încărcat la data de{" "}
                {files[fileToDelete]?.createdAt.toString().split("T")[0]}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                height: "fit-content",
              }}
            >
              <Button
                disabled={loadingDelete}
                onClick={() => setOpenModal(false)}
                variant="outlined"
                sx={{
                  width: "45%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  px: 4,
                  py: 1,
                  textTransform: "none",
                  color: Colors.BLACK,
                  borderColor: Colors.DARK_GREY,
                  bgcolor: Colors.WHITE,
                  ":hover": {
                    bgcolor: Colors.GREY_300,
                  },
                }}
              >
                Renunță
              </Button>
              <Button
                disabled={loadingDelete}
                onClick={() => onDelete(fileToDelete)}
                sx={{
                  width: "45%",
                  display: "flex",
                  alignItems: "center",
                  px: 4,
                  py: 1,
                  textTransform: "none",
                  color: `${Colors.WHITE} !important`,
                  bgcolor: Colors.SECONDARY,
                  ":hover": {
                    bgcolor: Colors.SECONDARY_DARK,
                  },
                }}
              >
                {loadingDelete ? (
                  <CircularProgress
                    sx={{ color: Colors.WHITE }}
                    size={"20px"}
                  />
                ) : (
                  "Confirmă"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <PageLayout
        title={"Concurs plan de afaceri"}
        subtitle={
          "Aici poți încărca toate documentele necesare planului tău de afaceri pentru concurs."
        }
      >
        <Grid container>
          <Grid item xs={12} lg={6}>
            <UploadFiles
              containerWidth={"100%"}
              containerHeight={window.innerWidth > 1024 ? "100%" : "50vh"}
              boxWidth={"100%"}
              boxHeight={"100%"}
              alignItems={"start"}
              _files={!!user.businessPlan ? user.businessPlan.files : []}
              getFiles={getUserFiles}
              uploadFiles={uploadUserFiles}
              loading={userFilesLoading}
              modal={false}
              settings={settings}
              loadingSettings={loadingSettings}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                pt: window.innerWidth > 1024 ? 4 : 0,
                pl: window.innerWidth > 1024 ? 8 : 4,
                pr: window.innerWidth > 1024 ? 5 : 4,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography variant={"subtitle1"} color={Colors.PRIMARY}>
                Lista fișierelor tale
              </Typography>

              <Box
                sx={{
                  pt: window.innerWidth > 1024 ? 5 : 1,
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={"caption"}
                  color={Colors.PRIMARY}
                  sx={{ width: "43%" }}
                >
                  Nume
                </Typography>
                <Typography
                  variant={"caption"}
                  color={Colors.PRIMARY}
                  sx={{ width: "25%" }}
                >
                  Data de încărcare
                </Typography>
                <Typography
                  variant={"caption"}
                  color={Colors.PRIMARY}
                  sx={{ width: "20%" }}
                >
                  Mărime
                </Typography>
              </Box>

              <Divider
                color={Colors.GREY_200}
                sx={{ width: "100%", height: 2, mt: 2, mb: 1 }}
              />

              <Box
                sx={{
                  height: window.innerWidth > 1024 ? "56vh" : "18vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {Object.values(files).length !== 0 ? (
                  Object.values(files).map((file: CustomFile, index) => (
                    <React.Fragment key={index}>
                      <Box
                        sx={{
                          py: 0.5,
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant={"caption"}
                          color={Colors.PRIMARY}
                          sx={{ width: "43%" }}
                        >
                          <span className={"limit-rows-to-2"}>{file.name}</span>
                        </Typography>
                        <Typography
                          variant={"caption"}
                          color={Colors.TEXT_SECONDARY}
                          sx={{ width: "25%", pl: 1 }}
                        >
                          <span className={"limit-rows-to-2"}>
                            {file.createdAt?.toString().split("T")[0]}
                          </span>
                        </Typography>
                        <Typography
                          variant={"caption"}
                          color={Colors.TEXT_SECONDARY}
                          sx={{ width: "20%" }}
                        >
                          <span className={"limit-rows-to-2"}>
                            {formatBytes(file.size)}
                          </span>
                        </Typography>
                        <Tooltip title={"Sterge fisierul"}>
                          <IconButton
                            disabled={
                              settings &&
                              (!settings.activeBusinessPlan || loadingSettings)
                            }
                            onClick={() => {
                              setFileToDelete(file.id);
                              setOpenModal(true);
                            }}
                          >
                            <img
                              src={"/icons/trash-icon.svg"}
                              alt={"back button"}
                              style={{
                                filter:
                                  "invert(55%) sepia(33%) saturate(248%) hue-rotate(165deg) brightness(86%) contrast(87%)",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Divider
                        color={Colors.GREY_200}
                        sx={{ width: "100%", height: 2, my: 1 }}
                      />
                    </React.Fragment>
                  ))
                ) : (
                  <Typography variant={"caption"} color={Colors.PRIMARY}>
                    Nu ai încărcat niciun fișier
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
};
