import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSnackbars } from "@app/modules/core/store/selectors";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { CloseSnackbar } from "@app/modules/core/store/actions";

const SnackbarDisplay = () => {
  const snackbar = useSelector(selectSnackbars);
  const dispatch = useDispatch();

  const onHandleClose = () => {
    dispatch(CloseSnackbar());
  };

  return (
    <>
      {snackbar && (
        <Snackbar
          open={true}
          autoHideDuration={4000}
          onClose={onHandleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={onHandleClose}
            severity={snackbar.severity as AlertColor}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarDisplay;
