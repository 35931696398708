import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentCourse } from "@app/modules/courses/store/selectors";
import {
  useLoadCurrentCourse,
  useParticipantSections,
} from "@app/modules/courses/hooks";
import { useParams } from "react-router-dom";
import { selectCurrentUser } from "@app/auth/store/selectors";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "@app/types/enums";
import { DividedPageLayout } from "@app/modules/courses/components";

export const StartCourse = () => {
  const { id } = useParams<"id">();
  const user = useSelector(selectCurrentUser);
  const { currentCourseLoading } = useLoadCurrentCourse(+id, user.id);
  const course = useSelector(selectCurrentCourse);

  const { participantLoading } = useParticipantSections();

  if (currentCourseLoading || participantLoading) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: Colors.SECONDARY }} size={"56px"} />
      </Box>
    );
  }

  return <DividedPageLayout course={course} />;
};
