import React from "react";
import { PageLayout } from "@app/common/components";
import { CourseCard } from "@app/modules/courses/components/course-card";
import { Box, Grid, Typography } from "@mui/material";
import { Course } from "@app/types/interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "@app/types/enums";
import { useSelector } from "react-redux";
import { selectParticipantOfCourseId } from "@app/modules/courses/store/selectors";

interface CoursesDisplayProps {
  courses: Course[];
  loading: boolean;
}

export const CoursesDisplay = ({ courses, loading }: CoursesDisplayProps) => {
  const coursesList = courses.map((course, index) => {
    const participant = useSelector(selectParticipantOfCourseId(course.id));
    return (
      <Grid item sm={12} lg={6} xl={4} key={index}>
        <CourseCard course={course} participant={participant} />
      </Grid>
    );
  });

  if (loading) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: Colors.SECONDARY }} size={"56px"} />
      </Box>
    );
  }

  return (
    <PageLayout
      title={"Cursurile mele"}
      subtitle={"Aici poți accesa toate cursurile de formare profesională"}
    >
      {coursesList?.length ? (
        <Grid container sx={{ width: "100%", pt: 3 }}>
          {coursesList}
        </Grid>
      ) : (
        <Typography
          height={"100%"}
          textAlign={"center"}
          display={"flex"}
          alignItems={"center"}
          variant={"h1"}
          color={Colors.SECONDARY_DARK}
        >
          Momentan nu aveti cursuri asignate.
        </Typography>
      )}
    </PageLayout>
  );
};
