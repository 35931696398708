import React from "react";
import { ProfileDisplay } from "@app/modules/profile/components";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/modules/auth/store/selectors";
import { useSupport } from "@app/modules/profile/hooks";

const Profile = () => {
  const user = useSelector(selectCurrentUser);
  const { support, loadingSupport } = useSupport();
  return (
    <ProfileDisplay user={user} support={support} loading={loadingSupport} />
  );
};

export default Profile;
