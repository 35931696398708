import React from "react";
import { CoursesDisplay } from "@app/modules/courses/components";
import {
  useLoadCourses,
  useLoadParticipants,
} from "@app/modules/courses/hooks";
import { useSelector } from "react-redux";
import { selectCoursesList } from "@app/modules/courses/store/selectors";

const Courses = () => {
  const { loadingCourses } = useLoadCourses();
  const { loadingParticipants } = useLoadParticipants();
  const courses = useSelector(selectCoursesList);
  return (
    <CoursesDisplay
      courses={courses}
      loading={loadingCourses || loadingParticipants}
    />
  );
};

export default Courses;
