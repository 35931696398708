import React from "react";
import { BusinessPlanDisplay } from "@app/modules/business-plan/components";
import { useSettings } from "@app/modules/core/hooks";

export const BusinessPlan = () => {
  const { settings, loadingSettings } = useSettings();
  return (
    <BusinessPlanDisplay
      settings={settings}
      loadingSettings={loadingSettings}
    />
  );
};
