import React, { useEffect, useState } from "react";
import { Colors } from "@app/types/enums";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Stage } from "@app/types/interfaces/stage.interface";
import { stageStillAvailable } from "@app/common/utilities";
import { format } from "date-fns";
import { ro } from "date-fns/locale";

interface MonitoringDisplayProps {
  stages: Stage[];
}

const MonitoringDisplay = ({ stages }: MonitoringDisplayProps) => {
  const [currentStage, setCurrentStage] = useState<Stage>(null);
  const noContent = stages.length === 0;

  useEffect(() => {
    const _currentStage = stages.find((_stage: Stage) =>
      stageStillAvailable(_stage)
    );
    setCurrentStage(_currentStage);
  }, []);

  const formContent = noContent ? (
    <Typography
      textAlign={"center"}
      variant={"h1"}
      color={Colors.SECONDARY_DARK}
      sx={{ p: 4, pt: 10 }}
    >
      Etapa de monitorizare nu a inceput inca
    </Typography>
  ) : (
    <iframe
      id={"formId"}
      style={{ width: "100%", height: "100%", borderWidth: 0 }}
      src={currentStage?.link}
    />
  );

  const stageList =
    !noContent &&
    stages.map((stage: Stage, index) => {
      const stillAvailable = stageStillAvailable(stage);
      return (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "space-between",
            width: "100%",
            height: "fit-content",
            my: 1.5,
            bgcolor: stage === currentStage && Colors.LIGHT_GREY,
            borderRadius: 3,
            p: 1.5,
            transition: "0.2s ease-in-out",
          }}
        >
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "start",
            }}
          >
            <img
              src={
                stillAvailable
                  ? "/icons/not-started-icon.svg"
                  : "/icons/finished-module.svg"
              }
              alt={"stage status"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                pl: 1,
              }}
            >
              <Typography
                color={Colors.PRIMARY}
                variant={"caption"}
                sx={{ pb: 0.5 }}
              >
                <span className={"limit-rows-to-3 sidenav-text word-wrap"}>
                  {stage.title}
                </span>
              </Typography>
              <Typography color={Colors.TEXT_SECONDARY} variant={"caption"}>
                {format(new Date(stage.deadline), "dd MMMM yyyy", {
                  locale: ro,
                })}
              </Typography>
            </Box>
          </Box>
          {stillAvailable && stage !== currentStage && (
            <IconButton
              onClick={() => {
                stillAvailable && setCurrentStage(stage);
              }}
            >
              <img
                src={"/icons/next-icon.svg"}
                alt={"open stage"}
                style={{
                  filter:
                    "invert(50%) sepia(18%) saturate(460%) hue-rotate(166deg) brightness(97%) contrast(82%)",
                }}
              />
            </IconButton>
          )}
        </Box>
      );
    });

  return (
    <Box
      sx={{
        color: Colors.BLACK,
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        px: 4,
      }}
    >
      <Typography variant={"h2"} color={Colors.BLACK} sx={{ mt: 4, pb: 2 }}>
        Monitorizare
      </Typography>
      <Typography variant={"subtitle2"} color={Colors.BLACK} sx={{ pb: 5 }}>
        Aici poți vedea stadiul etapelor de monitorizare a proiectului tău.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "80vh",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            width: "32%",
            borderRadius: 5,
            bgcolor: Colors.WHITE,
            p: 4,
          }}
        >
          <Box
            sx={{
              overflowY: "auto",
            }}
          >
            <Typography
              variant={"subtitle1"}
              color={Colors.PRIMARY}
              fontWeight={"700"}
            >
              Etape
            </Typography>
            <Divider
              color={Colors.GREY_300}
              sx={{ width: 113, height: 2, mt: 2, mb: 3 }}
            />
            {stageList}
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "start",
            width: "66%",
            borderRadius: 5,
            bgcolor: Colors.WHITE,
          }}
        >
          {formContent}
        </Box>
      </Box>
    </Box>
  );
};

export default MonitoringDisplay;
