import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/auth/store/selectors";
import { useState } from "react";
import {
  getBusinessPlanFilesService,
  setBusinessPlan,
} from "@app/modules/business-plan/services";
import { BusinessPlan, CustomFile } from "@app/types/interfaces";
import { formatIdArray } from "@app/common/utilities";
import {
  addFile,
  deleteFile,
  deleteSelectedFiles,
} from "@app/modules/courses/services";
import { getUser } from "@app/auth/services";
import { SetUser } from "@app/auth/actions";

export const useBusinessPlan = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  const [userFilesLoading, setUserFilesLoading] = useState(false);

  const deleteUserFile = async (fileId: number) => {
    try {
      await deleteFile(fileId);
      user.businessPlan.files = user.businessPlan.files.filter(
        (file: CustomFile) => file.id !== fileId
      );
      dispatch(SetUser(user));
    } catch (error) {
      throw error;
    }
  };

  const getUserFiles = async () => {
    setUserFilesLoading(true);

    try {
      let businessPlan: BusinessPlan;
      if (!!user.businessPlan) {
        businessPlan = await getBusinessPlanFilesService(user.businessPlan.id);
      }
      setUserFilesLoading(false);
      return businessPlan?.files;
    } catch (error) {
      setUserFilesLoading(false);
      throw error;
    }
  };

  const uploadUserFiles = async (
    oldFiles: { [name: string]: CustomFile },
    newFiles: { [name: string]: File }
  ) => {
    setUserFilesLoading(true);

    try {
      let businessPlan: BusinessPlan;

      if (!!user.businessPlan) {
        const idsToBeDeleted = [];
        user.businessPlan.files.forEach((_file: CustomFile) => {
          if (!oldFiles[_file.name]) {
            idsToBeDeleted.push(_file.id);
          }
        });

        idsToBeDeleted.length !== 0 &&
          (await deleteSelectedFiles(formatIdArray(idsToBeDeleted)));

        const updatedUser = await getUser();
        businessPlan = updatedUser.businessPlan;
      } else {
        businessPlan = await setBusinessPlan({
          user: user.id,
          files: [],
        } as BusinessPlan);
      }

      await Promise.all(
        Object.values(newFiles).map(async (_file: File) => {
          const pFile: FormData = new FormData();
          pFile.append("file", _file);
          pFile.append("title", _file.name);
          pFile.append("business_plan", businessPlan.id.toString());

          await addFile(pFile);
        })
      );

      const _businessPlan = await getBusinessPlanFilesService(businessPlan.id);
      dispatch(
        SetUser({
          ...user,
          businessPlan: _businessPlan,
        })
      );
      setUserFilesLoading(false);
    } catch (error) {
      setUserFilesLoading(false);
      throw error;
    }
  };

  return {
    uploadUserFiles,
    userFilesLoading,
    getUserFiles,
    deleteUserFile,
  };
};
