import { Tokens } from "@app/types/interfaces";

export const setTokens = ({ accessToken, refreshToken }: Tokens) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const getTokens = (): Tokens => {
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");

  return { accessToken, refreshToken } as Tokens;
};
