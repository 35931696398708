import React, { useEffect, useRef, useState } from "react";
import { Colors, Routes, Status } from "@app/types/enums";
import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Course,
  Participant,
  ParticipantTest,
  Section,
  Test,
} from "@app/types/interfaces";
import { useSelector } from "react-redux";

import {
  selectAllSections,
  selectAllTests,
  selectContentInProgress,
  selectCurrentParticipant,
  selectNextContent,
  selectParticipantCourseTest,
} from "@app/modules/courses/store/selectors";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";
import { formatDuration } from "@app/common/utilities";
import {
  ModuleRow,
  TestDisplay,
  UploadFiles,
} from "@app/modules/courses/components/index";
import { DeletedParticipant } from "@app/common/components";
import {
  useParticipantFiles,
  useParticipantSections,
  useParticipantTests,
} from "@app/modules/courses/hooks";
import CircularProgress from "@mui/material/CircularProgress";

interface DividedPageLayoutProps {
  course: Course;
}

export const DividedPageLayout = ({ course }: DividedPageLayoutProps) => {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [answers, setAnswers] = useState<{ [questionId: number]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const participant: Participant = useSelector(selectCurrentParticipant);
  const ref = useRef(null);
  const allSections = useSelector(selectAllSections);
  const allTests = useSelector(selectAllTests);
  const contentInProgress = useSelector(selectContentInProgress);
  const [currentParticipantTest, setCurrenParticipantTest] = useState(null);
  const [currentParticipantSection, setCurrentParticipantSection] =
    useState(null);
  const [test, setTest] = useState<Test>(null);
  const [loading, setLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState<Section>(null);
  const sectionsFinished = participant?.progress === 100;

  const {
    participantFilesLoading,
    uploadParticipantFiles,
    getParticipantFiles,
  } = useParticipantFiles();

  const {
    updateParticipantSectionsForStartingNextChapter,
    createPSection,
    deleteParticipantSections,
  } = useParticipantSections();

  const { createParticipantTest, deleteParticipantTests } =
    useParticipantTests();
  const nextContent = useSelector(selectNextContent(participant));
  const participantCourseTest = useSelector(
    selectParticipantCourseTest(course)
  );

  useEffect(() => {
    if (contentInProgress) {
      if (contentInProgress["isTest"]) {
        setCurrenParticipantTest(contentInProgress);
        setTest(allTests[(contentInProgress as ParticipantTest).test]);
        setCurrentParticipantSection(null);
        setCurrentSection(null);
      } else {
        setCurrentParticipantSection(contentInProgress);
        setCurrentSection(
          allSections[(contentInProgress as ParticipantSection).section]
        );
        setTest(null);
        setCurrenParticipantTest(null);
      }
    } else {
      setCurrentParticipantSection(null);
      setCurrenParticipantTest(null);
    }
  }, [contentInProgress]);

  useEffect(() => {
    // In case there are multiple sections/test in progress (could be the case when a module which is in progress is unassigned and assigned back after a while)
    if (contentInProgress) {
      if (contentInProgress["isTest"]) {
        const tests = testsInProgress();
        if (tests.length) {
          deleteParticipantTests(tests);
        }
      } else {
        const sections = sectionsInProgress();
        if (sections.length) {
          deleteParticipantSections(sections);
        }
      }
    }
    if (!contentInProgress && participant && nextContent) {
      startNextContent();
    }
  }, []);

  useEffect(() => {
    if (ref.current?.parentElement) {
      ref.current.parentElement.scrollTop = 0;
    }
  }, [currentSection]);

  const modulesList = course.modules.map(
    (module, index) =>
      module.sections && (
        <ModuleRow
          key={index}
          module={module}
          setCurrentSection={setCurrentSection}
          finalized={sectionsFinished}
          setTest={setTest}
          testInProgress={Object.keys(answers).length !== 0}
          setAnswers={setAnswers}
          submitted={submitted}
        />
      )
  );

  const onNextChapterButtonClick = async () => {
    setLoading(true);
    if (!nextContent) {
      setLoading(false);
      navigate(Routes.COURSES + "/" + course.id);
    }

    if (currentParticipantSection) {
      await updateParticipantSectionsForStartingNextChapter(
        currentSection.duration,
        currentParticipantSection,
        nextContent && !nextContent["isTest"] ? nextContent : null
      );
      if (nextContent && nextContent["isTest"]) {
        await createParticipantTest(nextContent);
      }
    } else if (!currentParticipantSection && !currentParticipantTest) {
      if (nextContent) {
        await startNextContent();
      }
    }
    setLoading(false);
  };

  const startNextContent = async () => {
    if (!nextContent["isTest"]) {
      await createPSection(nextContent);
    } else {
      await createParticipantTest(nextContent);
    }
  };

  const sectionsInProgress = () => {
    return participant?.sections?.filter(
      (participantSection: ParticipantSection) =>
        participantSection.section !==
          (contentInProgress as ParticipantSection).section &&
        participantSection.status === Status.IN_PROGRESS
    );
  };

  const testsInProgress = () => {
    return participant?.teste?.filter(
      (participantTest: ParticipantTest) =>
        participantTest.test !== (contentInProgress as ParticipantTest).test &&
        participantTest.status === Status.IN_PROGRESS
    );
  };

  if (!participant) {
    return <DeletedParticipant />;
  }

  return (
    <>
      <Modal open={opened} onClose={() => setOpened(false)}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <UploadFiles
            containerWidth={"100vw"}
            containerHeight={"100vh"}
            boxWidth={"40%"}
            boxHeight={"60%"}
            alignItems={"center"}
            _files={participant.files}
            getFiles={getParticipantFiles}
            uploadFiles={uploadParticipantFiles}
            loading={participantFilesLoading}
            modal={true}
            setOpened={setOpened}
          />
        </Box>
      </Modal>
      <Box
        sx={{
          color: Colors.BLACK,
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          px: 4,
        }}
      >
        <Button
          onClick={() => {
            setLoading(false);
            navigate(Routes.COURSES + "/" + course.id);
          }}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            width: "fit-content",
            textTransform: "none",
            my: 2,
          }}
        >
          <img src={"/icons/back-arrow.svg"} alt={"back arrow"} />
          <Typography variant={"body2"} color={Colors.PRIMARY} sx={{ pl: 1 }}>
            Mergi înapoi
          </Typography>
        </Button>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "90vh",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              width: "32%",
              borderRadius: 5,
              bgcolor: Colors.WHITE,
              p: 4,
            }}
          >
            <Box
              sx={{
                overflowY: "auto",
              }}
            >
              <Typography
                variant={"subtitle1"}
                color={Colors.PRIMARY}
                fontWeight={"700"}
              >
                Program de învățare
              </Typography>
              <Divider
                color={Colors.GREY_300}
                sx={{ width: 113, height: 2, mt: 2, mb: 3 }}
              />
              {modulesList}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  justifyContent: "start",
                  width: "100%",
                  cursor:
                    participantCourseTest &&
                    participantCourseTest.status !== Status.NOT_STARTED &&
                    "pointer",
                }}
                onClick={() =>
                  participantCourseTest &&
                  participantCourseTest.status !== Status.NOT_STARTED &&
                  setTest(course.test)
                }
              >
                <img
                  src={`${
                    !participantCourseTest
                      ? "/icons/not-started-icon.svg"
                      : participantCourseTest.status === Status.IN_PROGRESS
                      ? "/icons/right-arrow.svg"
                      : participantCourseTest.status === Status.NOT_STARTED
                      ? "/icons/not-started-icon.svg"
                      : "/icons/finished-module.svg"
                  }`}
                  style={{ maxWidth: "20px", height: "auto", paddingTop: 1 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    pl: 1,
                  }}
                >
                  <Typography color={Colors.PRIMARY} sx={{ pb: 0.5 }}>
                    <span className={"limit-rows-to-2 sidenav-text"}>
                      {course.test.title}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Button
              onClick={() => setOpened(true)}
              sx={{
                width: "100%",
                mt: 4,
                px: 4,
                py: 1,
                bgcolor: Colors.SECONDARY,
                ":hover": {
                  bgcolor: Colors.SECONDARY_DARK,
                },
              }}
            >
              <Typography
                variant={"button"}
                sx={{
                  textTransform: "none",
                  color: Colors.WHITE,
                  pr: 2,
                }}
              >
                Fișierele mele
              </Typography>
            </Button>
          </Box>
          <Box
            sx={{
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "start",
              width: "66%",
              borderRadius: 5,
              bgcolor: Colors.WHITE,
              p: 4,
              backgroundImage: test && "url(/images/success-bg.svg)",
              backgroundPosition: test && "bottom",
              backgroundRepeat: test && "no-repeat",
              backgroundSize: "100%",
            }}
          >
            <Box
              sx={{
                height:
                  test ||
                  currentParticipantSection?.section !== currentSection?.id ||
                  sectionsFinished
                    ? "100%"
                    : "90%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                overflowY: "auto",
                pr: 3,
              }}
            >
              {!test && (
                <Typography
                  variant={"body2"}
                  sx={{
                    width: "100%",
                    textAlign: "end",
                    pb: 1,
                  }}
                >
                  {currentSection && formatDuration(currentSection.duration)}
                </Typography>
              )}
              {!test && (
                <Typography
                  variant={"h3"}
                  fontWeight={"700"}
                  color={Colors.PRIMARY}
                  sx={{ pb: 4 }}
                >
                  {currentSection?.title}
                </Typography>
              )}
              {!test ? (
                <div
                  className={"fit-content"}
                  ref={ref}
                  dangerouslySetInnerHTML={{
                    __html: currentSection?.content,
                  }}
                />
              ) : (
                <TestDisplay
                  pTest={currentParticipantTest}
                  test={test}
                  onNextChapterButtonClick={onNextChapterButtonClick}
                  answers={answers}
                  setAnswers={setAnswers}
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                  buttonLoading={loading}
                  nextContent={
                    nextContent as ParticipantTest | ParticipantSection
                  }
                />
              )}
            </Box>
            {!test &&
              currentParticipantSection?.section === currentSection?.id &&
              !sectionsFinished && (
                <Box
                  sx={{
                    height: "10%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "end",
                  }}
                >
                  <Button
                    disabled={loading}
                    onClick={onNextChapterButtonClick}
                    sx={{
                      minWidth: "70%",
                      display: "flex",
                      alignItems: "center",
                      px: 4,
                      py: 1,
                      bgcolor: Colors.SUCCESS,
                      ":hover": {
                        bgcolor: Colors.SUCCESS_DARK,
                      },
                    }}
                  >
                    <Typography
                      variant={"button"}
                      sx={{
                        textTransform: "none",
                        color: Colors.WHITE,
                        pr: 2,
                      }}
                      fontWeight={700}
                    >
                      {loading ? (
                        <CircularProgress
                          sx={{ color: Colors.WHITE, mb: -0.5 }}
                          size={"20px"}
                        />
                      ) : nextContent && nextContent["isTest"] ? (
                        "Începe testul acestui modul"
                      ) : (
                        "Începe următorul capitol"
                      )}
                    </Typography>
                    {!loading && (
                      <img
                        src={"/icons/right-arrow.svg"}
                        style={{
                          filter:
                            "invert(100%) sepia(100%) saturate(1%) hue-rotate(211deg) brightness(110%) contrast(101%)",
                        }}
                      />
                    )}
                  </Button>
                </Box>
              )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
