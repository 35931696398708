import React, { FunctionComponent } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Routes } from "@app/types/enums";
import { useSelector } from "react-redux";
import { selectIsAuth } from "@app/auth/store/selectors";

interface RouteProps {
  component: FunctionComponent;
}

const PublicRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAuth = useSelector(selectIsAuth);
  const { pathname, state } = useLocation();

  return pathname === Routes.LOGIN && isAuth ? (
    <Navigate to={state ? state.returnUrl : Routes.COURSES} />
  ) : (
    <Component {...rest} />
  );
};

export default PublicRoute;
