import React from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { PageLayout } from "@app/common/components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLoadCurrentCourse } from "@app/modules/courses/hooks";
import {
  selectCurrentCourse,
  selectCurrentParticipant,
} from "@app/modules/courses/store/selectors";
import { Colors } from "@app/types/enums";
import { selectCurrentUser } from "@app/auth/store/selectors";
import { CourseProfileDisplay } from "@app/modules/courses/components";

export const CourseProfile = () => {
  const { id } = useParams<"id">();

  const user = useSelector(selectCurrentUser);

  const { currentCourseLoading } = useLoadCurrentCourse(+id, user.id);

  const course = useSelector(selectCurrentCourse);
  const participant = useSelector(selectCurrentParticipant);

  if (currentCourseLoading) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: Colors.SECONDARY }} size={"56px"} />
      </Box>
    );
  }

  return (
    <PageLayout backButton>
      <CourseProfileDisplay course={course} participant={participant} />
    </PageLayout>
  );
};
