import { Icons, Stage } from "@app/types/enums";

export interface SidenavItems {
  iconSrc: string;
  title: string;
  path: string;
  stage: Stage;
}

export const sidenavItems: SidenavItems[] = [
  {
    title: "Cursurile mele",
    path: "/my-courses",
    iconSrc: Icons.BOOK,
    stage: Stage.LEARNING,
  },
  {
    title: "Concurs plan de afaceri",
    path: "/business-plan",
    iconSrc: Icons.DOCUMENT,
    stage: Stage.BUSINESS_PLAN,
  },
  {
    title: "Monitorizare",
    path: "/monitoring",
    iconSrc: Icons.INTERNET,
    stage: Stage.MONITORING,
  },
];
