import { Course, Module, Section } from "@app/types/interfaces";
import { getType } from "typesafe-actions";
import {
  ResetCoursesStore,
  SetCoursesList,
  SetCurrentCourse,
} from "@app/modules/courses/store/actions";
import { createSectionsArr } from "@app/common/utilities";

export interface CoursesReducerState {
  courses: { [id: number]: Course };
  coursesLoaded: boolean;
  currentCourse: Course;
  currentCourseLoaded: boolean;
  currentSections: { [moduleId: number]: { [sectionId: number]: Section } };
}

const initialState: CoursesReducerState = {
  courses: {},
  coursesLoaded: false,
  currentCourse: null,
  currentCourseLoaded: false,
  currentSections: {},
};

export default (state = initialState, action): CoursesReducerState => {
  switch (action.type) {
    case getType(SetCoursesList): {
      const courses = action.payload.reduce((result, course: Course) => {
        result[course.id] = course;
        return result;
      }, {});

      return { ...state, courses: courses, coursesLoaded: true };
    }
    case getType(SetCurrentCourse): {
      const course = action.payload;
      const currSectionsArr = createSectionsArr(course);
      return {
        ...state,
        currentCourse: course,
        currentCourseLoaded: true,
        currentSections: currSectionsArr,
      };
    }
    case getType(ResetCoursesStore): {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
