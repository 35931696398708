import React from "react";
import MonitoringDisplay from "@app/modules/monitoring/components/monitoring-display";
import { useLoadStages } from "@app/modules/monitoring/hooks";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "@app/types/enums";
import { useSelector } from "react-redux";
import { selectStageList } from "@app/modules/monitoring/store/selectors";

const Monitoring = () => {
  const { loadingStages } = useLoadStages();
  const stages = useSelector(selectStageList);

  if (loadingStages) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: Colors.SECONDARY }} size={"56px"} />
      </Box>
    );
  }

  return <MonitoringDisplay stages={stages} />;
};

export default Monitoring;
