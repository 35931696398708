import { PaginatedData, Participant } from "@app/types/interfaces";
import api from "@app/api/api.service";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";

const participantPath = "/records/participant";
const participantSectionPath = "/records/participant_section";

const participantQueryParams = {
  datafy_fields:
    "id,course,user,files.id,files.file,files.name,files.size,sections.id,sections.section,sections.status,progress,duration,teste.id,teste.status,teste.score,teste.test,teste.participant_answers.id,teste.participant_answers.answer,teste.participant_answers.participant_test,teste.participant_answers.question",
};

export const getParticipantsOfUserId = (
  userId: string
): Promise<PaginatedData<Participant>> => {
  return api.get(`${participantPath}?user__eq=${userId}`, {
    params: participantQueryParams,
  });
};

export const createParticipant = (newParticipant: Participant) => {
  return api.post<void, Participant>(`${participantPath}`, newParticipant);
};

export const setParticipantSection = (
  participantSection: ParticipantSection
) => {
  return api.post<void, ParticipantSection>(
    `${participantSectionPath}`,
    participantSection
  );
};

export const bulkDeleteParticipantSections = (queryParams) => {
  return api.delete<void, void>(`${participantSectionPath}/bulk`, {
    params: queryParams,
  });
};

export const setParticipantSectionForNewlyStaredCourse = (
  section: ParticipantSection
) => {
  return api.post<void, ParticipantSection>(
    `${participantSectionPath}`,
    section
  );
};

export const updateStatusOfParticipantService = (
  pSectionId: number,
  status: string
) => {
  return api.patch<void, ParticipantSection>(
    `${participantSectionPath}/${pSectionId}`,
    {
      status,
    }
  );
};

export const updateParticipantProgress = (
  participantId: number,
  duration: number,
  progress: number
) => {
  return api.patch<void, Participant>(`${participantPath}/${participantId}`, {
    duration,
    progress,
  });
};

export const getParticipant = (
  courseId: number,
  userId: string
): Promise<PaginatedData<Participant>> => {
  return api.get(
    `${participantPath}?course__eq=${courseId}&user__eq=${userId}`,
    {
      params: participantQueryParams,
    }
  );
};
