import { useDispatch, useSelector } from "react-redux";
import { selectCurrentParticipant } from "@app/modules/courses/store/selectors";
import { CustomFile } from "@app/types/interfaces";
import {
  addFile,
  deleteSelectedFiles,
  getParticipantFiles as getParticipantFilesService,
} from "@app/modules/courses/services/participant-files.service";
import { UpdateParticipant } from "@app/modules/courses/store/actions";
import { useState } from "react";
import { formatIdArray } from "@app/common/utilities";

export const useParticipantFiles = () => {
  const participant = useSelector(selectCurrentParticipant);
  const dispatch = useDispatch();
  const [participantFilesLoading, setParticipantFilesLoading] = useState(false);

  const getParticipantFiles = async () => {
    setParticipantFilesLoading(true);

    try {
      const { results } = await getParticipantFilesService(participant.id);
      setParticipantFilesLoading(false);
      return results;
    } catch (error) {
      setParticipantFilesLoading(false);
      throw error;
    }
  };

  const uploadParticipantFiles = async (
    oldFiles: { [name: string]: CustomFile },
    newFiles: { [name: string]: File }
  ) => {
    setParticipantFilesLoading(true);

    const idsToBeDeleted = [];
    participant?.files?.forEach((_file: CustomFile) => {
      if (!oldFiles[_file.name]) {
        idsToBeDeleted.push(_file.id);
      }
    });
    idsToBeDeleted.length !== 0 &&
      (await deleteSelectedFiles(formatIdArray(idsToBeDeleted)));

    try {
      await Promise.all(
        Object.values(newFiles).map(async (_file: File) => {
          const pFile: FormData = new FormData();
          pFile.append("file", _file);
          pFile.append("name", _file.name);
          pFile.append("participant", participant.id.toString());

          await addFile(pFile);
        })
      );

      const { results } = await getParticipantFilesService(participant.id);
      participant.files = results;

      dispatch(UpdateParticipant(participant));
      setParticipantFilesLoading(false);
    } catch (error) {
      setParticipantFilesLoading(false);
      throw error;
    }
  };

  return {
    uploadParticipantFiles,
    getParticipantFiles,
    participantFilesLoading,
  };
};
