import { AppState } from "@app/store/reducers";
import { CoreReducerState } from "@app/modules/core/store/reducers/core.reducer";
import { createSelector } from "reselect";

const coreState = (state: AppState): CoreReducerState =>
  state.coreReducers.core;

export const selectSnackbars = createSelector(
  coreState,
  (state) => state.snackbar
);
