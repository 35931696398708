import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Colors } from "@app/types/enums";
import { FileDisplay } from "@app/modules/courses/components/file-display";
import { formatBytes, usePrompt } from "@app/common/utilities";
import { CustomFile, Settings } from "@app/types/interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbars } from "@app/modules/core/hooks";
import { useLocation } from "react-router-dom";

const _30MB = 31457280;

interface UploadFilesProps {
  containerWidth: string;
  containerHeight: string;
  boxWidth: string;
  boxHeight: string;
  alignItems: string;
  _files: CustomFile[];
  getFiles: () => any;
  uploadFiles: (
    oldFiles: { [name: string]: CustomFile },
    newFiles: { [name: string]: File }
  ) => any;
  loading: boolean;
  modal: boolean;
  setOpened?: (opened: boolean) => void;
  settings?: Settings;
  loadingSettings?: boolean;
}

export const UploadFiles = ({
  containerWidth,
  containerHeight,
  boxWidth,
  boxHeight,
  alignItems,
  _files,
  getFiles,
  uploadFiles,
  loading,
  modal,
  setOpened,
  settings,
  loadingSettings,
}: UploadFilesProps) => {
  const [oldFiles, setOldFiles] = useState<{ [name: string]: CustomFile }>({});
  const [newFiles, setNewFiles] = useState<{ [name: string]: File }>({});
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [oldFilesTotalFileSize, setOldFilesTotalFileSize] = useState(0);
  const [modified, setModified] = useState(false);
  const { pathname } = useLocation();
  const { openError, openSuccess, closeSnackbar } = useSnackbars();

  const uploadButton = "Upload Button";
  const { handleSubmit, register, watch } = useForm({
    mode: "onTouched",
  });
  const watchFile = watch(uploadButton, null);

  usePrompt(
    "Sigur vreţi să părăsiţi pagina? Aveți modificări nesalvate!",
    modified,
    pathname
  );

  const isInInitialState = (): boolean => {
    if (Object.keys(newFiles).length !== 0) return false;
    return Object.keys(oldFiles)?.length === _files?.length || !_files;
  };

  const initializeData = async (setTotalFileSize, setOldFiles) => {
    const files = await getFiles();
    let _totalFileSize = 0;
    let _oldFiles: { [name: string]: CustomFile } = {};
    files &&
      Array.from(files).forEach((_file: CustomFile) => {
        _totalFileSize += _file.size;
        _oldFiles[_file.name] = _file;
      });
    setOldFilesTotalFileSize(_totalFileSize);
    setTotalFileSize(_totalFileSize);
    setOldFiles(_oldFiles);
  };

  useEffect(() => {
    if (_files?.length !== 0) {
      initializeData(setTotalFileSize, setOldFiles);
    }
    return () => {
      setOldFiles({});
      setNewFiles({});
    };
  }, []);

  useEffect(() => {
    initializeData(setTotalFileSize, setOldFiles);
  }, [_files]);

  const onSubmitForm = async () => {
    setModified(false);
    window.history.pushState(
      {
        showConfirmation: false,
      },
      ""
    );
    try {
      await uploadFiles(oldFiles, newFiles);
      openSuccess({ message: "Fișierele au fost salvate cu succes!" });
      modal && setOpened(false);
      setOldFiles({});
      setNewFiles({});
    } catch (error) {
      openError({ message: "Ne pare rău, dar a apărut o eroare" });
      modal && setOpened(false);
    }
  };

  const _onRemoveItem = (name: string, size: number) => {
    if (!loading) {
      setTotalFileSize(totalFileSize - size);

      if (!!oldFiles[name]) {
        const _list = oldFiles;
        delete _list[name];
        setOldFiles({ ..._list });
      } else {
        const _list = newFiles;
        delete _list[name];
        setNewFiles({ ..._list });
      }

      if (isInInitialState()) {
        setModified(false);
        window.history.pushState(
          {
            showConfirmation: false,
          },
          ""
        );
      } else {
        setModified(true);
        window.history.pushState(
          {
            showConfirmation: true,
          },
          ""
        );
      }
    }
  };

  const isDisabled = () => {
    return settings && (!settings.activeBusinessPlan || loadingSettings);
  };

  useEffect(() => {
    if (!!watchFile && Object.values(watchFile).length !== 0) {
      closeSnackbar();

      let validFiles = true;
      Object.values(watchFile).forEach((_file: File) => {
        if (!!oldFiles[_file.name] || !!newFiles[_file.name]) {
          openError({
            message: `Mai exista un fisier cu numele "${_file.name}"! Fisierele trebuie sa aiba denumiri diferite!`,
          });
          validFiles = false;
          return;
        }
      });

      if (!validFiles) return;

      let newFilesSize = 0;
      Object.values(watchFile).forEach((file: File) => {
        newFilesSize += file.size;
        return newFilesSize;
      });

      const _totalFileSize = totalFileSize;

      if (newFilesSize + _totalFileSize <= _30MB) {
        const newAdditions = Object.values(watchFile).reduce(
          (list, file: File) => {
            list[file.name] = file;
            return list;
          },
          newFiles
        ) as { [name: string]: File };

        if (isInInitialState()) {
          setModified(false);
          window.history.pushState(
            {
              showConfirmation: false,
            },
            ""
          );
        } else {
          setModified(true);
          window.history.pushState(
            {
              showConfirmation: true,
            },
            ""
          );
        }

        setNewFiles({ ...newFiles, ...newAdditions });
        setTotalFileSize(_totalFileSize + newFilesSize);
      } else {
        openError({
          message: `Fisierele trebuie sa aiba maxim ${
            modal ? "30MB" : formatBytes(_30MB - oldFilesTotalFileSize)
          }`,
        });
      }
    }
  }, [watchFile]);

  const itemList = (files) =>
    Object.values(files).map((file: CustomFile | File, index) => (
      <Grid
        item
        xs={4}
        lg={4}
        xl={3}
        key={index}
        sx={{
          p: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FileDisplay
          title={file["title"] || file["name"]}
          size={file.size}
          removeItem={_onRemoveItem}
        />
      </Grid>
    ));

  const content = loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <CircularProgress sx={{ color: Colors.SECONDARY }} size={"60px"} />
    </Box>
  ) : (
    <Grid
      container
      alignItems="start"
      sx={{
        height: "fit-content",
        width: "100%",
        m: 0,
      }}
    >
      <Grid
        item
        xs={4}
        lg={4}
        xl={3}
        sx={{ pt: 1, display: "flex", justifyContent: "center" }}
      >
        <Tooltip title={"Adauga unul sau mai multe fisiere"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              position: "relative",
              bgcolor: Colors.LIGHT_GREY,
              width: 88,
              height: 88,
              p: 1,
              borderRadius: 2,
              mb: 1.5,
              transition: "0.2s ease-in-out",
              ":hover": {
                bgcolor: isDisabled() ? "" : Colors.GREY_200,
                cursor: isDisabled() ? "" : "pointer",
              },
            }}
          >
            <img
              src={"/icons/plus-icon.svg"}
              style={{
                filter:
                  "invert(51%) sepia(28%) saturate(286%) hue-rotate(165deg) brightness(92%) contrast(86%)",
              }}
            />

            <input
              multiple={true}
              accept="image/*, .pdf, .doc, .docx, .xml,
                         application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
                           .zip, .rar, .7zip"
              id={uploadButton}
              {...register(uploadButton)}
              type="file"
              style={{
                position: "absolute",
                width: 88,
                height: 88,
                contentVisibility: "hidden",
                cursor: isDisabled() ? "" : "pointer",
              }}
              disabled={isDisabled()}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
          </Box>
        </Tooltip>
      </Grid>
      {modal && itemList(oldFiles)}
      {itemList(newFiles)}
    </Grid>
  );

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={alignItems}
        sx={{
          width: containerWidth,
          height: containerHeight,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"start"}
          sx={{
            height: boxHeight,
            width: boxWidth,
            bgcolor: Colors.WHITE,
            borderRadius: 5,
            py: window.innerWidth > 1024 ? 4 : 2,
            px: 4,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <Typography variant={"subtitle1"} color={Colors.PRIMARY}>
              Încarcă fișierele tale
            </Typography>

            {modal ? (
              <Typography variant={"caption"} color={Colors.PRIMARY}>
                {formatBytes(totalFileSize)}/30 MB
              </Typography>
            ) : (
              <Typography variant={"caption"} color={Colors.PRIMARY}>
                {formatBytes(totalFileSize - oldFilesTotalFileSize)}/
                {formatBytes(_30MB - oldFilesTotalFileSize)}
              </Typography>
            )}
          </Box>

          <form
            onSubmit={handleSubmit(onSubmitForm)}
            style={{ height: "90%", width: "100%" }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  height: "90%",
                  width: "100%",
                  borderRadius: 5,
                  borderStyle: "dashed",
                  borderColor: Colors.GREY_300,
                  overflowY: "auto",
                  p: 3,
                }}
              >
                {content}
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-evenly"}
                sx={{
                  height: "8%",
                }}
              >
                <Button
                  disabled={loading || (!modal && !modified)}
                  onClick={() => {
                    initializeData(setTotalFileSize, setOldFiles);
                    setNewFiles({});
                    openSuccess({ message: "Modificarile au fost anulate!" });
                    setModified(false);
                    window.history.pushState(
                      {
                        showConfirmation: false,
                      },
                      ""
                    );
                    modal && setOpened(false);
                  }}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    px: 4,
                    py: 1,
                    textTransform: "none",
                    color: Colors.BLACK,
                    borderColor: Colors.DARK_GREY,
                    bgcolor: Colors.WHITE,
                    ":hover": {
                      bgcolor: Colors.GREY_300,
                    },
                  }}
                >
                  Anulează
                </Button>
                <Button
                  disabled={loading || !modified}
                  title="Submit"
                  type="submit"
                  sx={{
                    width: "150px",
                    display: "flex",
                    alignItems: "center",
                    px: 4,
                    py: 1,
                    textTransform: "none",
                    color: `${
                      modified ? Colors.WHITE : Colors.DARK_GREY
                    } !important`,
                    bgcolor: modified ? Colors.SECONDARY : Colors.GREY_200,
                    ":hover": {
                      bgcolor: Colors.SECONDARY_DARK,
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{ color: Colors.WHITE }}
                      size={"20px"}
                    />
                  ) : (
                    "Salvează"
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
