import api from "@app/api/api.service";
import { PaginatedData } from "@app/types/interfaces";
import { Stage } from "@app/types/interfaces/stage.interface";

const monitoringPath = "/records/stage";

const stageParams = {
  datafy_fields: "id,title,link,deadline",
};

export const getStagesService = (): Promise<PaginatedData<Stage>> => {
  return api.get(`${monitoringPath}`, {
    params: stageParams,
  });
};
