import { getActiveSupportList } from "@app/modules/profile/services";
import { useEffect, useState } from "react";
import { Support } from "@app/types/interfaces";

export const useSupport = () => {
  const [support, setSupport] = useState<Support>(null);
  const [loadingSupport, setLoadingSupport] = useState(true);

  useEffect(() => {
    getSupport();
  }, []);

  const getSupport = async () => {
    try {
      const activeSupportList = await getActiveSupportList();
      setSupport(activeSupportList.results[0]);
      setLoadingSupport(false);
    } catch (error) {
      setLoadingSupport(false);
      throw error;
    }
  };

  return {
    support,
    loadingSupport,
  };
};
