import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Colors, Routes } from "@app/types/enums";
import {
  ResetCoursesStore,
  ResetParticipantsStore,
} from "@app/modules/courses/store/actions";
import { useAuth } from "@app/auth/hooks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const DeletedParticipant = () => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    navigate(Routes.COURSES);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        textAlign={"center"}
        variant={"h1"}
        color={Colors.SECONDARY_DARK}
      >
        Administratorul v-a sters progresul acestui curs
      </Typography>
      <Button
        onClick={onButtonClick}
        sx={{
          mt: 2,
          px: 4,
          py: 1,
          bgcolor: Colors.SECONDARY,
          ":hover": {
            bgcolor: Colors.SECONDARY_DARK,
          },
        }}
      >
        <Typography
          variant={"button"}
          sx={{
            textTransform: "none",
            color: Colors.WHITE,
            pr: 2,
          }}
          fontWeight={700}
        >
          Întoarce-te la lista de cursuri
        </Typography>
      </Button>
    </Box>
  );
};
