import { createAction } from "typesafe-actions";
import { Snackbar } from "@app/types/interfaces";

const OPEN_SNACKBAR = "[Snackbar] Open";
const CLOSE_SNACKBAR = "[Snackbar] Close";

export const OpenSnackbar = createAction(
  OPEN_SNACKBAR,
  (snackbar: Snackbar) => snackbar
)<Snackbar>();
export const CloseSnackbar = createAction(CLOSE_SNACKBAR)<void>();
