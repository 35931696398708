import { PaginatedData, Settings } from "@app/types/interfaces";
import api from "@app/api/api.service";

const settingsPath = "/records/settings";

export const getActiveSettings = (): Promise<PaginatedData<Settings>> => {
  const queryParams = {
    datafy_fields: "id,active_business_plan",
    pageSize: 1,
  };
  return api.get(settingsPath, { params: queryParams });
};
