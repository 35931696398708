import React from "react";
import { Course, Module, Participant } from "@app/types/interfaces";
import { Box, LinearProgress, Typography } from "@mui/material";
import { Colors, Status } from "@app/types/enums";
import { selectCurrentCourseDuration } from "@app/modules/courses/store/selectors";
import { useSelector } from "react-redux";
import { CourseProfileOverview } from "@app/modules/courses/components/course-profile-overview";
import { LearningProgramOverview } from "@app/modules/courses/components/learning-program-overview";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";
import { uniqBy } from "lodash";

interface CourseProfileDisplayProps {
  course: Course;
  participant: Participant;
}

export const CourseProfileDisplay = ({
  course,
  participant,
}: CourseProfileDisplayProps) => {
  const progress = participant ? participant.progress : 0;
  const started = !!participant;
  const finalized =
    progress === 100 &&
    participant?.teste?.filter((test) => test.status !== Status.FINISHED)
      .length === 0 &&
    participant?.teste?.length ===
      course.modules.filter((module: Module) => !!module.test).length +
        (course.test ? 1 : 0);

  const nbModules = course.modules.length;
  const totalDuration = useSelector(selectCurrentCourseDuration());

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {/*LEFT SIDE*/}
      <Box
        sx={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        {/*LEFT SIDE - IMAGE*/}
        <Box
          className={"prevent-text-highlight"}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundImage: `linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)), url(${
              course.image ? course.image.file : "/images/card-image.png"
            })`,
            backgroundSize: "cover",
            height: "24vw",
            borderTopLeftRadius: 12,
          }}
        >
          <Typography
            variant={"subtitle2"}
            style={{
              position: "absolute",
              bottom: 38,
              left: 24,
              zIndex: 1,
              color: Colors.WHITE,
            }}
          >
            Progres:
          </Typography>

          <Box
            sx={{
              height: "10%",
              width: "60%",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              bottom: 15,
              left: 24,
            }}
          >
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                width: "80%",
                height: 4,
                borderRadius: 5,
              }}
            />
            <Typography variant={"caption"} color={Colors.WHITE} sx={{ ml: 2 }}>
              {progress}%
            </Typography>
          </Box>

          <Box
            sx={{
              bgcolor: finalized
                ? Colors.SUCCESS
                : started
                ? Colors.SECONDARY
                : Colors.LIGHT_GREY,
              color: started || finalized ? Colors.WHITE : Colors.PRIMARY,
              width: "fit-content",
              maxWidth: 80,
              px: 1,
              py: 0.5,
              borderRadius: 2,
              position: "absolute",
              zIndex: 1,
              bottom: 16,
              right: 24,
            }}
          >
            <span className={"tag limit-rows-to-1"}>
              {finalized ? "Finalizat" : started ? "Început" : "Neînceput"}
            </span>
          </Box>
        </Box>
        <CourseProfileOverview
          participant={participant}
          finalized={finalized}
          totalDuration={totalDuration}
          nbModules={nbModules}
          course={course}
        />
      </Box>

      {/*RIGHT SIDE*/}
      <Box
        sx={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <LearningProgramOverview course={course} />
      </Box>
    </Box>
  );
};
