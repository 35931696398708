import { getType } from "typesafe-actions";
import { SetUser, LogOut } from "@app/modules/auth/actions";
import { User } from "@app/types/interfaces";

export interface AuthReducerState {
  currentUser: User;
}

const initialState: AuthReducerState = {
  currentUser: null,
};

export default (state = initialState, action): AuthReducerState => {
  switch (action.type) {
    case getType(LogOut): {
      return {
        ...state,
        currentUser: null,
      };
    }
    case getType(SetUser): {
      const user: User = action.payload;
      return { ...state, currentUser: user };
    }

    default:
      return state;
  }
};
