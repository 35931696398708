import { createSelector } from "reselect";
import { AuthReducerState } from "@app/modules/auth/store/reducers/auth.reducer";
import { AppState } from "@app/store/reducers";

const authState = (state: AppState): AuthReducerState =>
  state.authReducers.auth;
export const selectCurrentUser = createSelector(
  authState,
  (state: AuthReducerState) => state.currentUser
);
export const selectIsAuth = createSelector(selectCurrentUser, (user) => !!user);
