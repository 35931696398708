import coreReducer, {
  CoreReducerState,
} from "@app/modules/core/store/reducers/core.reducer";
import { combineReducers } from "redux";

export interface CoreState {
  core: CoreReducerState;
}

export const coreReducers = combineReducers({
  core: coreReducer,
});
