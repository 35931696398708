import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Colors, Icons } from "@app/types/enums";
import { formatBytes } from "@app/common/utilities";

interface FileDisplayProps {
  title: string;
  size: number;
  removeItem: (title: string, size: number) => void;
}

export const FileDisplay = ({ title, size, removeItem }: FileDisplayProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        width: "fit-content",
        pt: 1,
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        sx={{
          bgcolor: Colors.LIGHT_GREY,
          width: 88,
          height: 88,
          p: 1,
          borderRadius: 2,
          position: "relative",
        }}
      >
        <img src={Icons.DOCUMENT} style={{ height: 12, width: 12 }} />
        <Tooltip title={title}>
          <Typography variant={"caption"} color={Colors.BLACK} sx={{ m: 0 }}>
            <span className={"limit-rows-to-1"}>{title}</span>
          </Typography>
        </Tooltip>
        <Tooltip title={"Șterge fișierul"}>
          <IconButton
            onClick={() => removeItem(title, size)}
            sx={{
              width: 16,
              height: 16,
              position: "absolute",
              top: -5,
              right: -5,
              bgcolor: Colors.WHITE,
              p: 1.5,
              ":hover": {
                bgcolor: Colors.LIGHT_GREY,
              },
            }}
          >
            <img
              src={"/icons/close-icon.svg"}
              style={{ width: 16, height: 16 }}
            />
          </IconButton>
        </Tooltip>
      </Box>

      <Typography
        variant={"caption"}
        color={Colors.NOT_STARTED}
        fontSize={10}
        sx={{ pl: 1 }}
      >
        {formatBytes(size)}
      </Typography>
    </Box>
  );
};
