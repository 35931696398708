import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { FieldPath } from "react-hook-form/dist/types";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import TextField from "@mui/material/TextField";

interface TextInputProps {
  control: Control<FieldValues>;
  name: string;
  label: string;
  type: string;
  required?: boolean;
  disabled?: boolean;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  variant?: "outlined" | "standard" | "filled";
  style?: {};
}

export const TextInput = ({
  variant = "filled",
  control,
  name,
  label,
  type,
  required,
  rules = {},
  style,
  disabled,
}: TextInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <TextField
          disabled={disabled}
          label={label}
          type={type}
          variant={variant}
          required={required}
          error={!!error}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          helperText={error ? error.message : null}
          style={style}
        />
      )}
      rules={
        required
          ? {
              ...rules,
              required: "Acest camp este obligatoriu!",
            }
          : rules
      }
    />
  );
};
