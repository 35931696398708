import axios from "axios";
import { Tokens } from "@app/types/interfaces";
import jwtDecode from "jwt-decode";
import { API } from "@app/constants";
import { getTokens, removeTokens, setTokens } from "@app/api";
import camelcaseKeys from "camelcase-keys";

const REFRESH_TOKEN_ROUTE = "/auth/token";
const UNAUTHORIZED_ROUTES = [REFRESH_TOKEN_ROUTE];

const api = axios.create({
  baseURL: API,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    if (UNAUTHORIZED_ROUTES.includes(config.url)) {
      return config;
    }

    await refreshIfExpired();

    const tokens = getTokens();
    if (tokens.refreshToken && tokens.accessToken) {
      config.headers["x-authentication"] = tokens.accessToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (res) => camelcaseKeys(res.data, { deep: true }),
  (err) => {
    const originalConfig = err.config;
    if (
      originalConfig.url === REFRESH_TOKEN_ROUTE &&
      err.response.status === 401
    ) {
      location.reload();
      removeTokens();
    }
    return Promise.reject(err);
  }
);

const isAccessTokenExpired = (accessToken: string): boolean => {
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  return Date.now() / 1000 - decoded.exp > 0;
};

const refreshAccessToken = async (refreshToken: string) => {
  try {
    const tokens = await api.post<void, Tokens>(REFRESH_TOKEN_ROUTE, {
      refreshToken,
    });
    setTokens({
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
    });
  } catch (error) {
    throw new Error(error);
  }
};

const refreshIfExpired = async (): Promise<void> => {
  const tokens = getTokens();
  if (tokens?.accessToken && isAccessTokenExpired(tokens.accessToken)) {
    try {
      await refreshAccessToken(tokens.refreshToken);
    } catch (error) {
      throw error;
    }
  }
};

export default api;
