export enum Colors {
  PRIMARY = "#14202A",
  PRIMARY_DARK = "#070C10",
  SECONDARY = "#58A9CA",
  SECONDARY_DARK = "#3C7DA2",
  INFO_MAIN = "#2196F3",
  TEXT_SECONDARY = "#6F8394",
  SECONDARY_LIGHT = "#070C10",
  LIGHT_GREY = "#F5F5F5",
  DARK_GREY = "#969798",
  ERROR = "#F02011",
  WHITE = "#fff",
  BLACK = "#000",
  GREY_200 = "#EEEEEE",
  GREY_300 = "#E0E0E0",
  NOT_STARTED = "#A6B8C6",
  SUCCESS = "#38B73D",
  SUCCESS_DARK = "#218925",
}
