import { createAction } from "typesafe-actions";
import { Course } from "@app/types/interfaces";

const SET_COURSES_LIST = "[Courses] Load Courses List";
const SET_CURRENT_COURSE = "[Course] Load Current Course";
const RESET_COURSES_STORE = "[Course] Reset";

export const SetCoursesList = createAction(
  SET_COURSES_LIST,
  (courses: Course[]) => courses
)<Course[]>();
SetCoursesList;

export const SetCurrentCourse = createAction(
  SET_CURRENT_COURSE,
  (course: Course) => course
)<Course>();
SetCurrentCourse;

export const ResetCoursesStore = createAction(RESET_COURSES_STORE)();
ResetCoursesStore;
