import { Snackbar } from "@app/types/interfaces";
import { getType } from "typesafe-actions";
import { CloseSnackbar, OpenSnackbar } from "@app/modules/core/store/actions";

export interface CoreReducerState {
  snackbar: Snackbar;
}

const initialState: CoreReducerState = {
  snackbar: null,
};

export default (state = initialState, action): CoreReducerState => {
  switch (action.type) {
    case getType(OpenSnackbar): {
      const snackbar: Snackbar = action.payload;

      return {
        ...state,
        snackbar: snackbar,
      };
    }
    case getType(CloseSnackbar): {
      return {
        ...state,
        snackbar: null,
      };
    }
    default:
      return state;
  }
};
