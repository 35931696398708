import {
  Course,
  Module,
  ParticipantTest,
  Section,
  Test,
} from "@app/types/interfaces";
import { Colors, Status } from "@app/types/enums";
import { Stage } from "@app/types/interfaces/stage.interface";

export const formatDuration = (duration: number) => {
  return duration < 60
    ? duration + " minute "
    : Math.floor(duration / 60) + " ore si " + (duration % 60) + " minute ";
};

export const calculateDurationOfModule = (module: Module) => {
  return module.sections.reduce((sectionDuration, section: Section) => {
    return sectionDuration + section.duration;
  }, 0);
};

export const calculateDurationOfCourse = (course: Course) => {
  return course?.modules.reduce((totalModuleDuration, module: Module) => {
    return totalModuleDuration + calculateDurationOfModule(module);
  }, 0);
};

export const selectNextContent = (
  currentModule: Module,
  nextModule: Module,
  sectionId: number,
  contentIsTest: boolean,
  pTest?: ParticipantTest
): Section | Test => {
  let nextContent: Section | Test;

  if (contentIsTest) {
    return pTest?.status === Status.FINISHED
      ? nextModule
        ? nextModule.sections[0]
        : null
      : currentModule.test;
  }

  if (!sectionId) {
    return nextModule.sections[0];
  }

  if (
    currentModule.sections[currentModule.sections.length - 1].id ===
      sectionId &&
    nextModule
  ) {
    nextContent = currentModule.test
      ? currentModule.test
      : nextModule.sections[0];
  } else {
    const currentIndex = currentModule.sections.findIndex(
      (section) => section.id === sectionId
    );
    nextContent =
      currentModule.sections.length - 1 > currentIndex
        ? currentModule.sections[currentIndex + 1]
        : null;
  }
  return nextContent;
};

export const getSectionTitle = (sectionId: number, module: Module) => {
  const found: Section = module.sections.find(
    (section) => section.id === sectionId
  );
  return found.title;
};

export const createSectionsArr = (course: Course) => {
  return course.modules.reduce((result, module: Module) => {
    result[module.id] = module.sections.reduce(
      (sectionResult, section: Section) => {
        sectionResult[section.id] = section;
        return sectionResult;
      },
      {}
    );
    return result;
  }, {});
};

export const getCorrectColor = (status: Status) => {
  return status === Status.IN_PROGRESS
    ? Colors.SECONDARY
    : status === Status.NOT_STARTED
    ? Colors.NOT_STARTED
    : Colors.SUCCESS;
};

export const formatIdArray = (ids: number[]) => {
  return ids.reduce((str, id) => str + id + ",", "").slice(0, -1);
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm)) + " " + "MB";
};

export const orderStagesByDeadline = (stages: Stage[]) => {
  return stages.sort(
    (first: Stage, second: Stage) =>
      new Date(first.deadline).valueOf() - new Date(second.deadline).valueOf()
  );
};

export const stageStillAvailable = (stage: Stage) => {
  return new Date().toISOString().split("T")[0] <= stage.deadline;
};

// https://docs.google.com/forms/d/e/1FAIpQLScNvjWoifI5GylIwawumHg5fCnJSEvUY1Jti6GD9H3NFyX6Ag/viewform?usp=sf_link
