// Create a theme instance.
import { createTheme } from "@mui/material/styles";
import { Colors } from "@app/types/enums";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.PRIMARY,
    },
    secondary: {
      main: Colors.SECONDARY,
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: Colors.WHITE,
        },
        barColorPrimary: {
          backgroundColor: Colors.SUCCESS,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: Colors.TEXT_SECONDARY,
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontStyle: "normal",
        },
        h1: {
          fontWeight: "700",
          fontSize: "32px",
          lineHeight: "48px",
        },
        h2: {
          fontSize: "24px",
          lineHeight: "36px",
          fontWeight: "700",
        },
        h3: {
          fontSize: "20px",
          lineHeight: "30px",
        },
        subtitle1: {
          fontSize: "16px",
          lineHeight: "24px",
        },
        subtitle2: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
        },
        body1: {
          fontFamily: "Open_Sans",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
        },
        body2: {
          fontFamily: "Open_Sans",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
        },
        caption: {
          fontFamily: "Open_Sans",
          fontWeight: "300",
          fontSize: "12px",
          lineHeight: "18px",
        },
        overline: {
          fontSize: "20px",
          lineHeight: "30px",
          letterSpacing: "4px",
        },
        button: {
          fontFamily: "Open_Sans",
          fontSize: "16px",
          lineHeight: "24px",
          letterSpacing: "0.15px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.LIGHT_GREY,
          borderRadius: "5px",
          "&.Mui-focused": {
            backgroundColor: `${Colors.WHITE} !important`,
            border: `1px solid ${Colors.SECONDARY}`,
          },
          "&.Mui-error": {
            border: `1px solid ${Colors.ERROR}`,
            backgroundColor: `${Colors.WHITE} !important`,
          },
          "&.Mui-error fieldset": {
            borderColor: `${Colors.ERROR} !important`,
            backgroundColor: `${Colors.WHITE} !important`,
          },
          "&.MuiFilledInput-underline:before": {
            borderBottom: "none",
          },
          "&.MuiFilledInput-underline:hover:before": {
            borderBottom: "none",
          },
          "&.MuiFilledInput-underline:after": {
            borderBottom: "none",
          },
          "&.Mui-disabled": {
            backgroundColor: `${Colors.GREY_200} !important`,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: Colors.PRIMARY,
          "&.Mui-focused": {
            color: `${Colors.SECONDARY} !important`,
            borderColor: "red !important",
          },
          "&.Mui-error": {
            color: `${Colors.ERROR} !important`,
          },
        },
      },
    },
  },
});

export default theme;
